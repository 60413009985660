import { HeadingLevel } from 'types/styles'
import { StyledH4 } from './styles'

export type H4Props = {
  as?: HeadingLevel
} & React.HTMLAttributes<HTMLHeadingElement>

export const H4 = (props: H4Props) => {
  const { children, ...rest } = props
  return <StyledH4 {...rest}>{children}</StyledH4>
}
