import { HeadingLevel } from 'types/styles'
import { StyledH2 } from './styles'

export type H2Props = {
  as?: HeadingLevel
} & React.HTMLAttributes<HTMLHeadingElement>

export const H2 = (props: H2Props) => {
  const { children, ...rest } = props
  return <StyledH2 {...rest}>{children}</StyledH2>
}
