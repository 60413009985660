import { useRef } from 'react'
import { H1 } from 'components/typography/H1'
import { useElementScrolled } from 'hooks/useScrolled'

import { StyledPageHeader, StyledChevronIcon, StyledBgImage, StyledHeading } from './styles'

type PageHeaderTypes = {
  children?: React.ReactNode
  heading?: string | React.ReactNode
  subHeading?: string | React.ReactNode
  fullHeight?: boolean
  showArrow?: boolean
  useBgImg?: boolean
}

function PageHeader(props: PageHeaderTypes) {
  const { children, heading, subHeading, showArrow = false, useBgImg = false, fullHeight = false } = props || {}
  const scrollToRef = useRef<HTMLDivElement>(null)
  const scrolledToTop = useElementScrolled(scrollToRef, 300)
  const scrollTo = () => {
    if (scrollToRef.current) {
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <StyledPageHeader bgImg={useBgImg} fullHeight={fullHeight}>
      {heading && (
        <StyledHeading fullHeight={fullHeight}>
          <H1>{heading}</H1>
          {subHeading && subHeading}
          {showArrow && <StyledChevronIcon direction="down" onClick={scrollTo} scrolled={scrolledToTop} />}
        </StyledHeading>
      )}
      {children && children}
      {useBgImg && <StyledBgImage />}
      <div ref={scrollToRef} />
    </StyledPageHeader>
  )
}

export default PageHeader
