import { breakpoint, rem } from 'helpers/functions'
import styled from 'styled-components'
import { getCssVar } from 'theme'

export const StyledLayout = styled.div`
    columns: 3;

    ${breakpoint('down', 'md')} {
      columns: 1;
    }
`

export const StyledImage = styled.div`
  cursor: pointer;
  width: 100%;
  margin-bottom: ${rem(10)};
  break-inside: avoid;
  border-radius: ${getCssVar('border.borderRadiusSize.sm')};

  img {
    max-width: 100%;
    min-width: 100%;
    border-radius: ${getCssVar('border.borderRadiusSize.sm')};
  }
`