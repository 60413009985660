import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'
import { breakpoint, rem } from 'helpers/functions'

export const StyledCountdown = styled.div`
  color: ${getCssVar('colors.auburn')};
  display: flex;
  flex-direction: column;
  font-family: ${getCssVar('font.headingFont')};
  font-weight: 400;
  font-style: normal;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1;
  margin: 0 auto;
  max-width: 950px;
  padding: ${rem(50)} 0;
  text-align: center;
  width: 100%;

  .small {
    font-size: ${rem(20)};
  }
`

export const StyledDate = styled.div<Partial<{}>>`
  font-size: ${rem(18)};
  margin-bottom: ${rem(50)};

  ${breakpoint('up', 'md')} {
    font-size: ${rem(24)};
  }

  ${breakpoint('up', 'md')} {
    font-size: ${rem(28)};
  }
`

export const StyledTimeValues = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledSimpleTime = styled.div`
  color: ${getCssVar('colors.auburn')};
  flex-direction: row;
  font-size: ${getCssVar('font.size.md')};
  padding: 0 ${rem(6)};

  ${breakpoint('down', 'md')} {
    > span:last-child { font-size: 60%; }
  }
`

export const StyledTime = styled.div<Partial<{ numbers: boolean }>>`
  flex: 1;
  font-size: ${getCssVar('font.size.lg')};
  position: relative;

  span {
    display: block;
    font-size: ${rem(10)};
    line-height: 2;
    margin-top: ${rem(30)};
    text-align: center;
    width: 100%;
  }

  ${(props) =>
    props.numbers &&
    css`
      &:after {
        content: ':';
        font-size: ${rem(20)};
        position: absolute;
        top: ${rem(36)};
        right: 0;
      }

      &:last-child:after {
        content: none;
      }
    `}

  ${breakpoint('up', 'md')} {
    font-size: ${rem(72)};

    span {
      font-size: ${rem(16)};
    }
  }

  ${breakpoint('up', 'lg')} {
    font-size: ${rem(92)};

    span {
      font-size: ${rem(20)};
    }
  }
`
