import { button } from './button'
import { input } from './input'
import { toggle } from './toggle'
import { card } from './card'
import { radioGroup } from './radioGroup'
import { modal } from './modal'
import { link } from './link'
import { statusFlag } from './statusFlag'
import { selectAutoComplete } from './selectAutoComplete'
import { popover } from './popover'
import { nav } from './nav'
import { switchComponent } from './switch'
import { checkbox } from './checkbox'
import { selectField } from './selectField'
import { tab } from './tab'
import { datePicker } from './datePicker'
import { stepper } from './stepper'
import { toast  } from './toast'

export const components = {
  button,
  input,
  toggle,
  card,
  radioGroup,
  modal,
  link,
  statusFlag,
  selectAutoComplete,
  popover,
  nav,
  switchComponent,
  checkbox,
  selectField,
  tab,
  datePicker,
  stepper,
  toast,
}
