import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isloggedIn } from 'helpers/auth'

export default function useLogout() {
  const navigate = useNavigate()
  const loggedIn = isloggedIn()

  useEffect(() => {
    if (!loggedIn) {
      navigate('/login')
    }
  }, [navigate, loggedIn])
}
