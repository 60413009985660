import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'
import { rem } from 'helpers/functions'

export const h4Styles = css`
  font-family: ${getCssVar('font.headingFont')};
  font-size: ${getCssVar('font.size.md')};
  font-weight: ${getCssVar('font.weight.semiBold')};
  line-height: ${getCssVar('font.lineHeight.regular')};
  color: ${getCssVar('body.headingColor')};
  letter-spacing: ${rem(1.25)};
`

export const StyledH4 = styled.h4`
  ${h4Styles}
`
