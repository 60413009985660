import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { generateCssVars, getCssVar, ThemeMode } from './'

export type GlobalStyleProps = {
  themeMode?: ThemeMode
  defaultFont?: 'Open Sans, sans-serif'
}

export const GlobalStyle: any = createGlobalStyle<GlobalStyleProps>`
  ${normalize}

  :root {
    ${({ themeMode }) => generateCssVars(themeMode)}
  }

  * {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: ${getCssVar('font.defaultFont')};
    font-size: ${getCssVar('font.size.base')};
    line-height: 1.6rem;
    font-weight: 400;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    color: ${getCssVar('body.textColor')};
    background-color: ${getCssVar('body.backgroundColor')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, ul, ol, dl, blockquote, table, pre, form {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
  }

  em {
    font-style: italic;
  }

  a {
    font-weight: 500;
    color: ${getCssVar('body.linkColor')};
    text-decoration: inherit;
  }

  a:hover {
    color: ${getCssVar('body.linkColor')};
    text-decoration: underline;
  }

  button {
    appearance: none;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
  }

  #app {
    min-height: 100vh;
    min-width: 100vw;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`
