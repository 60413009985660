import { jwtDecode } from 'jwt-decode'

export const isloggedIn = () => {
  const token = localStorage.getItem('token')
  if (!token) return false

  try {
    const decoded: any = jwtDecode(token)
    return decoded.exp > Date.now() / 1000
  } catch (error) {
    return false
  }
}
