import styled from 'styled-components'
import { getCssVar } from 'theme'
import { rem } from 'helpers/functions'
import { LabelProps } from './types'

export const StyledLabel = styled.label<Partial<LabelProps>>`
  color: ${getCssVar('input.labelColor')};
  display: block;
  font-family: ${getCssVar('font.defaultFont')};
  font-size: ${(props) => getCssVar(`font.size.${props.size || 'sm'}`)};
  font-weight: ${getCssVar('font.weight.medium')};
  line-height: 1.2;
  margin: 0 0 ${rem(10)} 0;
  text-align: center;

  span {
    color: ${getCssVar('input.error.textColor')};
  }

  svg {
    color: ${getCssVar('input.tooltipColor')};
  }
`
