import { useEffect, useState } from 'react'
import { ToggleProps } from './types'
import { StyledToggleGroup, StyledToggleButton } from './styles'

export const ToggleGroup = StyledToggleGroup
export const ToggleButton = StyledToggleButton

export const Toggle = (props: ToggleProps) => {
  const { toggles, value, defaultValue, onChange, fullWidth, size } = props
  const [toggleValue, setToggleValue] = useState<string>(value || defaultValue || '')

  useEffect(() => {
    if (defaultValue && typeof onChange === 'function') {
      onChange(defaultValue)
    }
  }, [])

  useEffect(() => {
    if (value || value === '') {
      setToggleValue(value)
    }
  }, [value])

  const handleChange = (value: string) => {
    setToggleValue(value)
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  return (
    <ToggleGroup
      type="single"
      value={toggleValue}
      defaultValue={defaultValue}
      onValueChange={(value) => {
        if (value) handleChange(value)
      }}
      fullWidth={fullWidth}
      size={size}
    >
      {toggles.map(({ label, value }) => (
        <ToggleButton fullWidth={fullWidth} key={value} value={value} size={size}>
          {label}
        </ToggleButton>
      ))}
    </ToggleGroup>
  )
}
