import styled, { css, keyframes } from 'styled-components'
import { SizeProps } from 'types/styles'
import { rem } from 'helpers/functions'

export type LoadingRingProps = {
  className?: string
  size?: SizeProps | number
}

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledLoadingRing = styled.div<LoadingRingProps>`
  & > .loader {
    display: block;
    position: relative;
    width: 1em;
    height: 1em;

    & > div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 0.8em;
      height: 0.8em;
      margin: 0.1em;
      border: 0.1em solid currentColor;
      border-radius: 50%;
      animation: ${loadingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: currentColor transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  ${(props) =>
    typeof props.size === 'number' &&
    css`
      width: ${rem(props.size)};
      height: ${rem(props.size)};
      font-size: ${rem(props.size)};
    `}
`

export const LoadingRing = (props: LoadingRingProps) => {
  const { className, size = 'md' } = props

  return (
    <StyledLoadingRing className={className} size={size}>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </StyledLoadingRing>
  )
}
