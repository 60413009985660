import { hexToRGBa, rem } from 'helpers/functions'
import { colors } from '../colors'

export const modal = {
  bgColor: colors.white,
  hoverCloseBgColor: hexToRGBa(colors.black, 0.08),
  borderRadius: rem(8),
  border: undefined,
  overlay: {
    bgColor: colors.black,
    opacity: 0.6,
  },
}
