import { hexToRGBa } from 'helpers/functions'
import { colors } from '../colors'

export const nav = {
  navBar: {
    bgColor: colors.white,
    shadowColor: hexToRGBa(colors.black, 0.1),
    menuColor: colors.darkGrey,
    menuActiveColor: colors.navy,
    iconColor: colors.darkGrey,
    iconActiveColor: colors.navy,
    avatarBgColor: colors.blue,
    avatarTextColor: colors.white,
  },
  dropdown: {
    bgColor: colors.white,
    textColor: colors.darkGrey,
    textColorAlt: colors.blue,
    textColorDark: colors.slate,
    textColorActive: colors.navy,
    borderColor: colors.midGrey,
    borderRadius: '4px',
    shadowColor: hexToRGBa(colors.navy, 0.1),
    buttonBgColor: colors.white,
    buttonBgColorHover: colors.lightGrey,
    buttonBgColorProminent: colors.lightGrey,
    buttonBgColorProminentHover: colors.midGrey,
  },
  hamburger: {
    bgColor: colors.white,
    borderColor: colors.border,
    buttonBgColor: colors.white,
    buttonBgColorHover: colors.lightGrey,
    iconColor: colors.darkGrey,
    textColor: colors.darkGrey,
    textColorHover: colors.navy,
    shadowColor: hexToRGBa(colors.navy, 0.1),
  },
}
