import { hexToRGBa } from 'helpers/functions'
import { colors } from '../colors'

export const toggle = {
  bgColor: hexToRGBa(colors.midGrey, 0.2),
  textColor: colors.darkGrey,
  selectedBgColor: colors.auburn,
  selectedTextColor: colors.white,
  focusBorderColor: hexToRGBa(colors.darkGrey, 0.25),
  hoverBgColor: hexToRGBa(colors.auburn, 0.15),
  hoverTxtColor: colors.slate,
}
