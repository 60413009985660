import { hexToRGBa } from 'helpers/functions'
import { colors } from '../colors'

export const card = {
  bgColor: colors.white,
  borderColor: colors.midGrey,
  topLabelBgColor: colors.midGrey,
  topLabelTextColor: colors.white,
  activeBorderColor: colors.teal,
  activeTopLabelBgBolor: colors.teal,
  shadowColor: hexToRGBa(colors.navy, 0.15),
}
