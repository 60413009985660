import React, { useRef, useEffect } from 'react'
import { colors as cols } from 'theme/colors'

interface Circle {
  x: number
  y: number
  dx: number
  dy: number
  radius: number
  color: string
  draw: (context: CanvasRenderingContext2D) => void
  update: (context: CanvasRenderingContext2D, width: number, height: number) => void
}

export const BackgroundDots: React.FC<{ inSection?: boolean }> = ({ inSection = false } : { inSection?: boolean }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const circleArrayRef = useRef<Circle[]>([])

  const createCircle = (x: number, y: number, dx: number, dy: number, radius: number, color: string): Circle => {
    return {
      x,
      y,
      dx,
      dy,
      radius,
      color,
      draw(context: CanvasRenderingContext2D) {
        context.fillStyle = this.color
        for (let i = 0; i < 5; i++) {
          // Draw multiple layers for blur effect
          context.globalAlpha = 0.2 // Reduce opacity for each layer
          context.beginPath()
          context.arc(
            this.x + (Math.random() - 0.5) * 2,
            this.y + (Math.random() - 0.5) * 2,
            this.radius,
            0,
            Math.PI * 2,
            false
          )
          context.fill()
          context.closePath()
        }
        context.globalAlpha = 1.0 // Reset opacity
      },
      update(context: CanvasRenderingContext2D, width: number, height: number) {
        if (this.x + this.radius > width || this.x - this.radius < 0) {
          this.dx = -this.dx
        }
        if (this.y + this.radius > height || this.y - this.radius < 0) {
          this.dy = -this.dy
        }
        this.x += this.dx
        this.y += this.dy
        this.draw(context)
      },
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const context = canvas.getContext('2d')
    if (!context) return

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    const circleArray: Circle[] = []
    for (let i = 0; i < 50; i++) {
      const radius = Math.random() * 3
      const x = Math.random() * (window.innerWidth - radius * 2) + radius
      const y = Math.random() * (window.innerHeight - radius * 2) + radius
      const dx = (Math.random() - 0.5) / 5
      const dy = (Math.random() - 0.5) / 3
      const colors = [cols.auburn, cols.earth, cols.lightBrown, cols.yellow]
      const color = colors[Math.floor(Math.random() * colors.length)]
      circleArray.push(createCircle(x, y, dx, dy, radius, color))
    }
    circleArrayRef.current = circleArray

    const animate = () => {
      if (!canvas || !context) return
      context.clearRect(0, 0, window.innerWidth, window.innerHeight)
      circleArrayRef.current.forEach((circle) => circle.update(context, window.innerWidth, window.innerHeight))
      requestAnimationFrame(animate)
    }

    animate()

    const handleResize = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return <canvas ref={canvasRef} style={{ position: inSection ? 'absolute' : 'fixed', top: 0, left: 0, zIndex: 0 }} />
}
