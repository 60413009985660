import { ImageProps } from './types'
import { StyledImage } from './styles'

type ImageBaseProps = ImageProps & { className?: string; children: React.ReactNode; onClick?: any }

export const Image = (props: ImageBaseProps) => {
  const { className, children, onClick } = props
  return (
    <StyledImage className={className} aria-hidden="true" onClick={onClick}>
      {children}
    </StyledImage>
  )
}
