import { useRef } from 'react'
import PageWrapper from 'components/layout/pageWrapper'
import { BackgroundVideo } from 'components/display/video'
import PageFooter from 'components/layout/pageFooter'
import Navigation from 'components/navigation'
import { BackgroundDots } from 'components/display/background'
import { useDistanceScrolled } from 'hooks/useScrolled'
import { isloggedIn } from 'helpers/auth'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { ToastAlert } from 'pages/components/toast'

import { StyledNavContainer, StyledBgImg } from './styles'

function PageLayout({ children }: { children: React.ReactNode }) {
  const navRef = useRef<HTMLDivElement>(null)
  const scrolled = useDistanceScrolled(1)
  const loggedIn = isloggedIn()
  const isMobile = useBreakpoint('down', 'md')
  return (
    <PageWrapper>
      {loggedIn && (
        <StyledNavContainer ref={navRef}>
          <Navigation scrolled={scrolled} />
        </StyledNavContainer>
      )}
      <ToastAlert />
      {children}
      {/* <PageFooter /> */}
      <BackgroundDots />
      {isMobile ? <StyledBgImg /> : <BackgroundVideo />}
    </PageWrapper>
  )
}

export default PageLayout
