import { useEffect } from 'react'
import { ToastOptions, useToastStore } from 'store/toast'

export default function useToast() {
    const { showToast } = useToastStore()

    useEffect(() => {
        return () => {
            const { toasts } = useToastStore.getState()
            toasts.forEach((toast: ToastOptions) => useToastStore.getState().removeToast(toast.id))
        }
    }, [])

    return { showToast }
}