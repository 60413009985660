import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { isEmpty } from 'lodash'
import PageLayout from 'components/layout/pageLayout'
import PageHeader from 'components/layout/pageHeader'
import PageContent from 'components/layout/pageContent'
import PageSection from 'components/layout/pageSection'
import Countdown from 'pages/components/countdown'
import useLogout from 'hooks/useLogout'
import { StyledH2, StyledH3, StyledLogo, StyledButtons, StyledContent, PageSectionLight, StyledColContent, PageSectionGifts, PageSectionDark, StyledLink, StyledColumns, StyledA } from './styles'
import { Button } from 'components/inputs/Button'
import { H3 } from 'components/typography/H3'
import { H4 } from 'components/typography/H4'
import { P } from 'components/typography/P'
import { Space } from 'components/layout/Space'
import { AttireIcon } from 'components/icons/AttireIcon'
import { DateTimeIcon } from 'components/icons/DateTimeIcon'
import { LocationIcon } from 'components/icons/LocationIcon'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { InputField } from 'components/inputs/InputField'
import { DateRangePickerComponent } from 'components/inputs/DatePicker'
import { emails } from './data'

function Home() {
  const isMobile = useBreakpoint('down', 'md')
  const [people, setPeople] = useState<string>('2')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setendDate] = useState<string>('')
  useLogout()

  const scrollToRef = useRef<HTMLDivElement>(null)
  const scrollTo = () => {
    if (scrollToRef.current) {
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleDatesChange = (startDate: Moment | null, endDate: Moment | null) => {
    if (startDate) {
      setStartDate(startDate.format('YYYY-MM-DD'))
    }
    if (endDate) {
      setendDate(endDate.format('YYYY-MM-DD'))
    }
  }

  return (
    <PageLayout>
      <PageHeader
        heading={
          <Link to="/">
            <StyledLogo />
          </Link>
        }
        subHeading={(
          <>
            <Space align="h" size="lg" />
            <H3>Invite you to celebrate their marriage!</H3>
            <Space align="h" size="xl" />
            <Link to="/rsvp"><Button>RSVP</Button></Link>
          </>
        )}
        showArrow
        fullHeight
      />
      <PageContent>
        <PageSectionLight showDots={!isMobile}>
          <StyledH2>
            <span>Thursday</span>
            <span>April 17, 2025</span>
          </StyledH2>
          <Countdown />
          <Space align="h" size="lg" />
          <StyledColumns>
            <div>
              <Space align="h" size="xxl" />
              <LocationIcon />
              <StyledColContent>
                <H4>Getting There</H4>
                <Space align="h" size="lg" />
                <P>Inglewood Estate</P>
                <Space align="h" size="xs" />
                <P>Located in Kangaroo Ground, Eltham (not in Inglewood), it's a 45 min Uber from Melbourne CBD. You can also take the train to Eltham and it's a short 10 min taxi/uber from there.</P>
                <Space align="h" size="lg" />
              </StyledColContent>
              <a target="_BLANK" href="https://www.google.com/maps/dir//Inglewood+Estate+130+Eltham-Yarra+Glen+Rd+Kangaroo+Ground+VIC+3095+Australia/@-37.7045359,145.2096061,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x6ad636b9544d8653:0x4a3c774abb0ee366"><Button>Directions</Button></a>
              <Space align="h" size="lg" />
            </div>
            <div>
              <Space align="h" size="xxl" />
              <AttireIcon />
              <StyledColContent>
                <H4>What to wear?</H4>
                <Space align="h" size="lg" />
                <P>Formal Attire</P>
                <Space align="h" size="xs" />
                <P>Make yourself look fancy but bring your dancing shoes, there will be a live band from 8:00 pm onwards!</P>
                <Space align="h" size="lg" />
              </StyledColContent>
              <Link to="/schedule"><Button>Schedule</Button></Link>
              <Space align="h" size="lg" />
            </div>
            <div>
              <Space align="h" size="xxl" />
              <DateTimeIcon />
              <StyledColContent>
                <H4>When is it?</H4>
                <Space align="h" size="lg" />
                <P>3:30 pm - Thursday, 17th April, 2025</P>
                <Space align="h" size="xs" />
                <P>The wedding starts at 3:30 pm and ends at 10:45. It's on the Thursday before the Easter weekend so anyone planning on staying near by should try to book accomodation ASAP!</P>
                <Space align="h" size="lg" />
              </StyledColContent>
              <Button onClick={scrollTo}>Book Accomodation</Button>
              <Space align="h" size="lg" />
            </div>
          </StyledColumns>
          <Space align="h" size="lg" />
          <Space align="h" size="lg" />
          {/* <StyledButtons>
            <StyledLink to="/rsvp"><Button>RSVP</Button></StyledLink>
            <Link to="/schedule"><Button>Schedule</Button></Link>
          </StyledButtons> */}
          <div ref={scrollToRef} />
        </PageSectionLight>
        {/* <PageSectionVenue showDots>
          <StyledH3>
            <span>Venue</span>
            <span>Inglewood Estate</span>
          </StyledH3>
          <Space align="h" size="lg" />
          <P>Nestled in the scenic landscape of Eltham, Victoria, Inglewood Estate is a charming wedding venue located at 130 Eltham-Yarra Glen Road, Kangaroo Ground, VIC 3097. It's easy to reach from Melbourne CBD by car via the M3 Eastern Freeway, taking the Eltham exit and continuing on Eltham-Yarra Glen Road until you see the estate on your left. Ample parking is available with clear signs guiding you to the designated areas. For those using public transport, take the Hurstbridge Line train from Melbourne CBD to Eltham Station, followed by a short 10-minute taxi or rideshare trip to the venue. Ubers and taxis are also readily available; simply enter "Inglewood Estate, Kangaroo Ground" as your destination, and your driver will bring you directly to the venue. We look forward to celebrating with you at this beautiful location!</P>
          <Space align="h" size="xl" />
          <StyledButtons>
            <a target="_BLANK" href="https://www.google.com/maps/dir//Inglewood+Estate+130+Eltham-Yarra+Glen+Rd+Kangaroo+Ground+VIC+3095+Australia/@-37.7045359,145.2096061,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x6ad636b9544d8653:0x4a3c774abb0ee366"><Button variant="outline" color="white">Directions</Button></a>
          </StyledButtons>
        </PageSectionVenue> */}
        <PageSectionDark showDots>
          <StyledH3>
            <span>Accomodation</span>
            <span>Find Accomodation Nearby</span>
          </StyledH3>
          <Space align="h" size="lg" />
          <StyledButtons>
            <InputField
              placeholder="Enter your email"
              onChange={(e) => setPeople((e.target as HTMLInputElement).value)}
              onEnterKey={(e) => {
                const val = (e.target as HTMLInputElement).value
              }}
              defaultValue={people}
              value={people}
              type="number"
            />
            <DateRangePickerComponent onDatesChange={handleDatesChange} />
          </StyledButtons>
          <StyledButtons>
            <StyledA target="_BLANK" href={`https://www.airbnb.com.au/s/Eltham--Victoria--Australia/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-09-01&monthly_length=3&monthly_end_date=2024-12-01&price_filter_input_type=2&channel=EXPLORE&query=Eltham%2C%20VIC&place_id=ChIJfcIpW7Q31moRQNKMIXVWBAU&date_picker_type=calendar&checkin=${startDate}&checkout=${endDate}9&adults=${people}&source=structured_search_input_header&search_type=user_map_move&search_mode=regular_search&price_filter_num_nights=2&ne_lat=-37.661338651797315&ne_lng=145.19000516007418&sw_lat=-37.77647173925647&sw_lng=145.09193225214608&zoom=12.986512873589543&zoom_level=12.986512873589543&search_by_map=true`} disabled={isEmpty(startDate) || isEmpty(endDate)}><Button variant="outline" color="white" disabled={isEmpty(startDate) || isEmpty(endDate)}>AirBnb</Button></StyledA>
            <StyledA target="_BLANK" href={`https://www.booking.com/searchresults.html?ss=Kangaroo+Ground%2C+Victoria%2C+Australia&efdco=1&label=gen173nr-1FCAEoggI46AdIM1gEaA-IAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4AsLasbYGwAIB0gIkNTk4ZTA0ODgtM2YyNS00MjA1LTg3ZjAtNjdhODg0ODdkZWY32AIF4AIB&aid=304142&lang=en-us&sb=1&src_elem=sb&src=index&dest_id=900059843&dest_type=city&ac_position=0&ac_click_type=b&ac_langcode=en&ac_suggestion_list_length=2&search_selected=true&search_pageview_id=936f53e1fb5a17cb&ac_meta=GhA5MzZmNTNlMWZiNWExN2NiIAAoATICZW46D2thbmdhcm9vIGdyb3VuZEAASgBQAA%3D%3D&checkin=${startDate}&checkout=${endDate}&group_adults=${people}&no_rooms=1&group_children=0`} disabled={isEmpty(startDate) || isEmpty(endDate)}><Button variant="outline" color="white" disabled={isEmpty(startDate) || isEmpty(endDate)}>Booking.com</Button></StyledA>
          </StyledButtons>
        </PageSectionDark>
        <PageSectionGifts showDots={!isMobile}>
          <StyledH3>
            <span>Gifts</span>
            <span>What to get us!</span>
          </StyledH3>
          <StyledContent>
            <Space align="h" size="xl" />
            <P>Having everyone there to celebrate with us means more than anything you could ever get us.</P>
            <Space align="h" size="md" />
            <P>If you really feel like getting us something there will be a wishing well at the venue, but please just bring yourselves and be ready to party!</P>
            <Space align="h" size="xxl" />
            <StyledLink to="/rsvp"><Button>RSVP</Button></StyledLink>
          </StyledContent>
          <Space align="h" size="lg" />
        </PageSectionGifts>
        <PageSectionDark showDots>
          <StyledContent>
            <H3>We're counting down to our big day! We hope you can spend it with us. Please RSVP by Nov 30, 2024.</H3>
          </StyledContent>
        </PageSectionDark>
      </PageContent>
    </PageLayout>
  )
}

export default Home
