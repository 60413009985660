import styled, { css } from 'styled-components'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { getCssVar } from 'theme'
import { rem, breakpoint } from 'helpers/functions'
import { ToggleProps } from 'components/inputs/Toggle/types'

export const StyledToggleGroup = styled(ToggleGroupPrimitive.Root)<Partial<ToggleProps>>`
  display: inline-flex;
  padding: ${rem(6)};
  background-color: ${getCssVar('toggle.bgColor')};
  border-radius: ${rem(22)};
  user-select: none;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.size &&
    css`
      ${props.size === 'sm' &&
      css`
        border-radius: ${rem(22)};
        padding: ${rem(6)};
      `}
      ${props.size === 'md' &&
      css`
        border-radius: ${rem(32)};
        padding: ${rem(8)};
      `}
      ${props.size === 'lg' &&
      css`
        border-radius: ${rem(42)};
        padding: ${rem(10)};
      `}
    `}
`

export const StyledToggleButton = styled(ToggleGroupPrimitive.Item)<Partial<ToggleProps>>`
  border-radius: ${rem(16)};
  font-size: ${getCssVar('font.size.sm')};
  height: ${rem(32)};
  padding: 0 ${rem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${getCssVar('toggle.textColor')};
  font-family: ${getCssVar('font.defaultFont')};
  font-size: ${getCssVar('font.size.sm')};
  font-weight: ${getCssVar('font.weight.semiBold')};
  line-height: 1;
  text-align: center;
  transition: ${getCssVar('transitions.short')};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  & + & {
    margin-left: ${rem(6)};
  }

  &:hover {
    background-color: ${getCssVar('toggle.hoverBgColor')};
    color: ${getCssVar('toggle.hoverTxtColor')};
  }

  &[data-state='on'] {
    color: ${getCssVar('toggle.selectedTextColor')};
    background-color: ${getCssVar('toggle.selectedBgColor')};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${getCssVar('toggle.focusBorderColor')};
    outline: 0;
  }

  ${(props) =>
    props.size &&
    css`
      ${props.size === 'sm' &&
      css`
        border-radius: ${rem(16)};
        font-size: ${getCssVar('font.size.sm')};
        height: ${rem(32)};
        padding: 0 ${rem(20)};
      `}
      ${props.size === 'md' &&
      css`
        border-radius: ${rem(24)};
        font-size: ${getCssVar('font.size.md')};
        height: ${rem(46)};
        padding: 0 ${rem(30)};
      `}
      ${props.size === 'lg' &&
      css`
        border-radius: ${rem(40)};
        font-size: ${getCssVar('font.size.lg')};
        height: ${rem(60)};
        padding: 0 ${rem(40)};
      `}
    `}

  ${props => (props.size === 'sm' || props.size === 'md' || props.size === 'lg') && css`
    ${breakpoint('down', 'md')} {
      border-radius: ${rem(16)};
      font-size: ${getCssVar('font.size.sm')};
      height: ${rem(32)};
      padding: 0 ${rem(20)};
    }
  `}
`
