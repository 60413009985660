import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Decorator } from 'theme/decorator'
import { GlobalStyle } from 'theme/global'
import Routes from 'routes'

const App: React.FC = () => {
  return (
    <Decorator>
      <GlobalStyle />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Decorator>
  )
}

export default App
