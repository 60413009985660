import { Link } from 'react-router-dom'
import { default as LoginComp } from 'components/auth/login'
import PageHeader from 'components/layout/pageHeader'
import { StyledLogo } from '../home/styles'
import { StyledLayout, StyledContent, StyledHeading } from './styles'
import { H1 } from 'components/typography/H1'



function Login() {
  return (
    <StyledLayout>
      <PageHeader>
        <StyledHeading>
         <H1>
            <Link to="/">
              <StyledLogo />
            </Link>
          </H1>
        </StyledHeading>
      </PageHeader>
      <StyledContent>
        <LoginComp />
      </StyledContent>
    </StyledLayout>
  )
}

export default Login
