import { create } from 'zustand'

export type ToastOptions = {
  id: number
  message: string
  success: boolean
}

type ToastStore = {
  toasts: ToastOptions[]
  showToast: (options: ToastOptions) => void
  removeToast: (id: number) => void
}

export const useToastStore = create<ToastStore>((set: (arg0: { (state: { toasts: ToastOptions[] }): { toasts: ToastOptions[] }; (state: { toasts: ToastOptions[] }): { toasts: ToastOptions[] } }) => void) => {
  let toastId = 0

  return {
    toasts: [],
    showToast: (options: ToastOptions) => {
      set((state: { toasts: ToastOptions[] }) => ({
        toasts: [...state.toasts, { ...options, id: toastId++ }],
      }))
    },
    removeToast: (id: number) => {
      set((state: { toasts: ToastOptions[] }) => ({
        toasts: state.toasts.filter((toast: ToastOptions) => toast.id !== Number(id)),
      }))
    },
  }
})

export const showToast = (options: ToastOptions) =>
  useToastStore.getState().showToast(options)