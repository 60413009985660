import { StyledSpace } from './styles'
import { SpaceProps, SpaceBaseProps } from './types'

export const Space = (props: SpaceProps) => {
  const { inline } = props
  return <StyledSpace as={inline ? 'span' : 'div'} aria-hidden {...props} />
}

export const VSpace = (props: SpaceBaseProps) => {
  return <Space align="h" {...props} />
}

export const HSpace = (props: SpaceBaseProps) => {
  return <Space align="v" {...props} />
}
