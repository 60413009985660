import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'
import { breakpoint, rem } from 'helpers/functions'

export const h1Styles = css`
  font-family: ${getCssVar('font.headingFont')};
  font-size: ${getCssVar('font.size.xxl')};
  font-weight: ${getCssVar('font.weight.thin')};
  line-height: ${getCssVar('font.lineHeight.regular')};
  color: ${getCssVar('body.headingColor')};
  letter-spacing: ${rem(1.4)};
  text-align: center;
`

export const StyledH1 = styled.h1`
  ${h1Styles}

  ${breakpoint('down', 'md')} {
    font-size: ${getCssVar('font.size.lg')};
  }
`
