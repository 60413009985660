import PageLayout from 'components/layout/pageLayout'
import PageSection from 'components/layout/pageSection'
import { H3 } from 'components/typography/H3'
import { P } from 'components/typography/P'
import { Space } from 'components/layout/Space'

import Countdown from '../components/countdown'
import RSVPform from '../components/rsvp'
import PageHeader from 'components/layout/pageHeader'

function RSVP() {
  return (
    <PageLayout>
      <PageHeader heading="RSVP" subHeading={(
        <>
          <Space align="h" size="xxl" />
          <P>Slide left or right to respond for your partner/significant other.</P>
          <Space align="h" size="lg" />
        </>
      )} />
      <RSVPform />
    </PageLayout>
  )
}

export default RSVP
