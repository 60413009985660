import React, { useMemo } from 'react'
import styled from 'styled-components'
import useWindowDimensions from 'hooks/useWindowDimensions'

const StyledBgVideo = styled.div`
  position: fixed;
  pointer-events: none;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;

  video {
    pointer-events: none !important;
  }
`

export const BackgroundVideo: React.FC = () => {
  const { width } = useWindowDimensions()

  const bgSource = useMemo(() => width <= 2200 ?  '/background-portrait.mp4': '/background-landscape.mp4', [width])

  return (
    <StyledBgVideo className="video-container">
      <video key={bgSource} autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
        <source src={bgSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </StyledBgVideo>
  )
}
