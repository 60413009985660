import { Icon } from '.'
import { IconProps } from './types'

export const WarningIconSVG = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="M24.2975 21.6841L17.4912 8.07652C17.1597 7.41088 16.4942 7 15.7518 7C15.0094 7 14.344 7.41088 14.0124 8.07652L7.20587 21.6841C6.90359 22.2912 6.93541 22.9992 7.29326 23.5773C7.64856 24.1553 8.26902 24.5 8.94781 24.5H22.5554C23.2342 24.5 23.8546 24.1553 24.2099 23.5773C24.568 22.9992 24.5998 22.2912 24.2975 21.6841ZM14.7156 13.6097C14.7156 13.0385 15.1806 12.5735 15.7518 12.5735C16.323 12.5735 16.788 13.0385 16.788 13.6097V18.0161C16.788 18.5873 16.323 19.0523 15.7518 19.0523C15.1806 19.0523 14.7156 18.5873 14.7156 18.0161V13.6097ZM15.7518 21.938C15.1585 21.938 14.6757 21.4552 14.6757 20.8619C14.6757 20.2684 15.1585 19.7856 15.7518 19.7856C16.3451 19.7856 16.8279 20.2684 16.8279 20.8619C16.8279 21.4552 16.3451 21.938 15.7518 21.938Z" />
  </svg>
)

export const WarningIcon = (props: IconProps) => (
  <Icon {...props}>
    <WarningIconSVG />
  </Icon>
)