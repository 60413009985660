import { useState, MouseEvent, useEffect } from 'react'
import { InputField } from 'components/inputs/InputField'
import { H3 } from 'components/typography/H3'
import { Toggle } from 'components/inputs/Toggle'
import { TextArea } from 'components/inputs/Textarea'
import { Space } from 'components/layout/Space'
import { Label } from 'components/inputs/Label'
import useToast from 'hooks/useToast'
import { fetchUser, updateUser, UserProps } from './resource'

import { StyledUser, StyledSection, FormCarousel, StyledDetails, StyledButton } from './styles'
import { Spinner } from 'components/loaders/spinner'

function RSVPform() {
  const [users, setUsers] = useState<UserProps[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  const { showToast } = useToast()

  const onChange = (type: string, value: string | boolean, index: number) => {
    setUsers((prevUsers) => {
      const updatedUsers = [...prevUsers];
      updatedUsers[index] = { ...updatedUsers[index], [type]: value };
      return updatedUsers;
    })
  }

  const updateDetails = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setSaving(true)
    updateUser({ users, showToast })
    setSaving(false)
  }

  useEffect(() => {
    fetchUser(setUsers, setLoading)
  }, [])

  return (
    <StyledSection>
      <StyledUser>
        <form>
          {loading ? <Spinner /> : (
            <FormCarousel
              showCount={1}
              slides={
                (users || []).map((user: UserProps, i) => {
                  const { name, dietary, attending, allergies, reason, message } = user || {}
                  return (
                    <StyledDetails key={i}>
                      <H3>{name}</H3>
                      <Space align="h" size="xxl" />
                      <Label htmlFor="attending">Will {name} be attending?!</Label>
                      <Toggle
                        key={i + '-attending'}
                        value={attending}
                        toggles={[
                          { value: 'Yes', label: 'Will Attend' },
                          { value: 'No', label: `Won't Attend` },
                        ]}
                        onChange={(value: string) => onChange('attending', value, i)}
                        size="md"
                        fullWidth
                      />
                      {attending === "No" && (
                        <>
                          <Space align="h" size="xxl" />
                          <Label htmlFor="reason">Reason for not attending?</Label>
                          <TextArea
                            key={i + '-reason'}
                            value={reason}
                            onChange={(e) => onChange('reason', e.target.value, i)}
                            fullWidth
                          />
                        </>
                      )}
                      <Space align="h" size="xxl" />
                      <Label htmlFor="diet">Dietary requirements? <br /><span>(if other, please specify in allergies!)</span></Label>
                      <Toggle
                        key={i + '-dietary'}
                        value={dietary}
                        toggles={[
                          { value: 'Meat Eater', label: 'Meat Eater' },
                          { value: 'Vegan', label: 'Vegan' },
                          { value: 'Vege', label: 'Vege' },
                        ]}
                        onChange={(value: string) => onChange('dietary', value, i)}
                        fullWidth
                      />
                      <Space align="h" size="xxl" />
                      <Label htmlFor="allergies">Do you have any allergies?</Label>
                      <InputField
                        key={i + '-allergies'}
                        placeholder="none..."
                        value={allergies}
                        onChange={(e) => onChange('allergies', e.target.value, i)}
                      />
                      <Space align="h" size="xxl" />
                      <Label htmlFor="message">Private message to Sam and Bec</Label>
                      <TextArea
                        key={i + '-messages'}
                        value={message}
                        onChange={(e) => onChange('message', e.target.value, i)}
                        fullWidth
                      />
                      <Space align="h" size="sm" />
    
                    </StyledDetails>
                  )
                })
              }
            />
          )}
          

          <Space align="h" size="md" />
          <StyledButton label="Update Details" onClick={(e) => updateDetails(e)} isLoading={saving} />
        </form>
      </StyledUser>
    </StyledSection>
  )
}

export default RSVPform
