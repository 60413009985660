import styled, { css } from 'styled-components'
import { H2 } from 'components/typography/H2'
import { H3 } from 'components/typography/H3'
import { breakpoint, rem, hexToRGBa } from 'helpers/functions'
import { getCssVar } from 'theme'
import { BecAndSam } from 'components/display/images/becandsam'
import PageSection from 'components/layout/pageSection'
import { Link } from 'react-router-dom'

export const PageSectionLight = styled(PageSection)`
  background: ${hexToRGBa('#f3f2f0', 0.8)} !important;
`

export const PageSectionInfo = styled(PageSection)`
  background: ${hexToRGBa('#da9a3e', 0.8)} !important;
  color: ${getCssVar('colors.white')};

  h2, h3, h4, p, div, span {
    color: ${getCssVar('colors.white')};
    text-align: justify;
  }
`


export const PageSectionDark = styled(PageSection)`
  background: ${getCssVar('colors.darkerBrown')} !important;
  color: ${getCssVar('colors.white')};

  h2, h3, h4, p, div, span {
    color: ${getCssVar('colors.white')};
  }

  p { text-align: justify; }
`

export const PageSectionGifts = styled(PageSection)`
  background: ${hexToRGBa('#f3f2f0', 0.8)} !important;
  text-align: center;
`

export const StyledColumns = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
    width: 33.3%;

    p, h3, H4 {
      text-align: center;
    }

    p {
      font-size: ${getCssVar('font.size.sm')};
    }

    i {
      margin: 0 auto;
      width: ${rem(70)};
    } 
  }

  ${breakpoint('down', 'md')} {
    flex-direction: column;
    > div { width: 100%; }
  }
`

export const StyledColContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(30)};
  flex-grow: 3;

  ${breakpoint('down', 'md')} {
    padding: ${rem(20)} ${rem(10)};
  }
`

export const StyledH2 = styled(H2)`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${rem(72)};
  margin: 0 auto;
  max-width: 700px;

  span {
    display: flex;
  }

  > span:first-child {
    font-size: 40%;
  }

  ${breakpoint('down', 'lg')} {
    font-size: ${rem(48)};
    line-height: ${rem(48)};
  }  

  ${breakpoint('down', 'md')} {
    font-size: ${rem(40)};
    line-height: ${rem(42)};
  }

  ${breakpoint('down', 'sm')} {
    font-size: ${rem(32)};
    line-height: ${rem(36)};
  }  
`

export const StyledContent = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

export const StyledH3 = styled(H3)`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${rem(56)};
  line-height: ${rem(56)};
  margin: 0 auto ${rem(20)};
  // max-width: 700px;
  text-align: center;

  span {
    display: flex;
  }

  > span:first-child {
    font-size: 40%;
  }

  ${breakpoint('down', 'lg')} {
    font-size: ${rem(48)};
    line-height: ${rem(48)};
  }  

  ${breakpoint('down', 'md')} {
    font-size: ${rem(40)};
    line-height: ${rem(42)};
  }

  ${breakpoint('down', 'sm')} {
    font-size: ${rem(32)};
    line-height: ${rem(36)};
  }  
`

export const StyledScrollTo = styled.div<Partial<{ scrolled: boolean }>>``

export const StyledLogo = styled(BecAndSam)`
  align-self: center;
  color: ${getCssVar('colors.auburn')};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  padding-left: ${rem(20)};
  position: relative;
  width: 100%;
  z-index: 10;
`

export const StyledMapSection = styled.div<Partial<{ fullHeight: boolean }>>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  height: 60vh;
`

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin: ${rem(20)} 0;

  ${breakpoint('down', 'md')} {
    flex-direction: column;

    .DateRangePickerInput {
      width: 100%;
    }
  }
`

export const StyledLink = styled(Link)<Partial<{ scrolled?: boolean }>>`
  // position: ${props => props.scrolled ? 'fixed' : 'relative'};
  // top: ${props => props.scrolled ? '93px' : 'auto'};
  // width: ${props => props.scrolled ? '100%' : 'auto'};
  position: sticky;
  top: 93px;
`

export const StyledA = styled.a<{ disabled: boolean }>`
  ${props => props.disabled && css` pointer-events: none; `}

  ${breakpoint('down', 'md')} {
    width: 100%;

    > button { width: 100%; }
  }
`
