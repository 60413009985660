import styled, { createGlobalStyle } from 'styled-components'
import { DateRangePicker } from 'react-dates'
import { getCssVar } from 'theme'
import { breakpoint, lightenDarkenColor } from 'helpers/functions'

export const GlobalStyles = createGlobalStyle`
    .CalendarMonth_caption,
    .CalendarMonth_caption strong,
    .ctquSY div {
        color: ${getCssVar('body.textColor')};
    }

    .CalendarDay__selected_span {
        background: ${lightenDarkenColor('#bc5e30', 110)};
        border-color: ${lightenDarkenColor('#bc5e30', 110)};
        color: ${getCssVar('colors.auburn')};
    }

    .CalendarDay__default:hover,
    .CalendarDay__hovered_span {
        background: ${lightenDarkenColor('#bc5e30', 90)};
        border-color: ${lightenDarkenColor('#bc5e30', 90)};
        color: ${getCssVar('colors.auburn')};
    }

    .CalendarDay__selected,
    .CalendarDay__selected:hover {
        background: ${getCssVar('colors.auburn')};
        border-color: ${getCssVar('colors.auburn')};
        color: ${getCssVar('colors.white')};
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before,
    .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
        border-right-color: ${getCssVar('colors.auburn')};
    }

    ${breakpoint('down', 'sm')} {
        .DateRangePickerInput__showClearDates {
            padding-right: 0;
        }
    }
`