import { Icon } from '.'
import { IconProps } from './types'

export const HidePasswordIconSVG = ({ fill }: { fill?: string }) => (
    <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="m964.65 164.65-146.3 146.3c-65.945-39.66-141.4-60.723-218.35-60.949-201.35 0-374.2 144.05-450 350 31.352 87.094 83.031 165.45 150.75 228.55l-136.1 136.1c-9.6445 9.3203-15.145 22.121-15.262 35.531-0.11719 13.41 5.1602 26.309 14.645 35.789 9.4805 9.4844 22.379 14.762 35.789 14.645 13.41-0.11719 26.211-5.6172 35.531-15.262l800-800c9.6445-9.3203 15.145-22.121 15.262-35.531 0.11719-13.41-5.1602-26.309-14.645-35.789-9.4805-9.4844-22.379-14.762-35.789-14.645-13.41 0.11719-26.211 5.6172-35.531 15.262zm-499.65 499.5c-9.8008-19.969-14.93-41.906-15-64.148 0-39.781 15.805-77.938 43.934-106.07s66.285-43.934 106.07-43.934c22.242 0.070312 44.18 5.1992 64.148 15zm525.4-183.85c24.152 37.617 44.137 77.754 59.602 119.7-75.801 205.95-248.65 350-450 350-24.426-0.019531-48.801-2.1758-72.852-6.4492z"/>
   </svg>
)

export const HidePasswordIcon = (props: IconProps & { fill?: string }) => {
  const { fill } = props
  return (
    <Icon {...props}>
      <HidePasswordIconSVG fill={fill} />
    </Icon>
  )
}