import { breakpoint, rem } from 'helpers/functions'
import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'

export const h3Styles = css`
  font-family: ${getCssVar('font.headingFont')};
  font-size: ${getCssVar('font.size.lg')};
  font-weight: ${getCssVar('font.weight.regular')};
  line-height: ${getCssVar('font.lineHeight.large')};
  color: ${getCssVar('body.headingColor')};
  letter-spacing: ${rem(1.25)};

  ${breakpoint('down', 'md')} {
    font-size: ${getCssVar('font.size.md')};
  }
`

export const StyledH3 = styled.h3`
  ${h3Styles}
`

