import { useState, MouseEvent, useEffect } from 'react'
import axios from 'axios'
import { ToastOptions } from 'store/toast'

export type UserProps = {
    name: string
    dietary: string
    attending: string
    allergies: string
    reason: string
    message: string
}

export const fetchUser = async (setUsers: (value: UserProps[]) => void, setLoading: (val: boolean) => void) => {
    const token = localStorage.getItem('token')
    if (!token) return
    try {
      setLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { user, partner, children = [] } = response.data
      const { attending, ...rest } = user
      const { attending: partnerAttending, ...restPartner } = partner || {}

      const updatedChildren = children.map(({ attending: childAttending, ...restChild }: UserProps) => ({ ...restChild, attending: childAttending ? 'Yes' : 'No' }))

      const users = [{
        attending: attending ? 'Yes' : 'No',
        ...rest,
      }]

      if (partner) {
        users.push({
          attending: partnerAttending ? 'Yes' : 'No',
          ...restPartner
        })
      }

      setUsers([...users, ...updatedChildren])
      setLoading(false)
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  }


type UpdateUsers = {
    users: UserProps[]
    showToast: (val: ToastOptions) => void
}


export const updateUser = async ({ users, showToast }: UpdateUsers) => {
    const token = localStorage.getItem('token')
    if (!token) return
    const newUsers = users.map(({ attending, ...rest }) => ({
      ...rest,
      attending: attending === 'Yes' ? true : false
    }))
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/user`, newUsers, {
        headers: {
            Authorization: `Bearer ${token}`
        }
        })
        const { data } = response || {}
        const { success, error } = data || {}
        showToast({ id: 1, success, message: success ? 'Your details have been udpated!' : error || 'An error occured, please try again.' })
    } catch (error) {
        console.error('Error updating user:', error);
        showToast({ id: 1, success: false, message: 'An error occured, please try again.' })
    }
}