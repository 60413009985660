import { hexToRGBa } from 'helpers/functions'
import { colors } from '../colors'

export const checkbox = {
  bgColor: colors.white,
  textColor: colors.darkGrey,
  borderColor: colors.auburn,
  disabledColor: hexToRGBa(colors.darkGrey, 0.75),
  selectedBgColor: colors.auburn,
  selectedTextColor: colors.white,
  focusBorderColor: colors.yellow,
  hoverBgColor: hexToRGBa(colors.darkGrey, 0.15),
}
