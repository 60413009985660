import React from 'react'
import styled, { keyframes } from 'styled-components'
import { getCssVar } from 'theme'
import { rem } from 'helpers/functions'

export type SpinnerProps = {
  className?: string
}

const spinKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledSpinnerWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(40)};
`

const StyledSpinner = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 4px solid ${getCssVar('colors.midGrey')};
  border-top-color: ${getCssVar('colors.auburn')};
  animation: ${spinKeyframes} 750ms infinite linear;
`

export const Spinner = ({ className }: SpinnerProps) => (
  <StyledSpinnerWrap className={className}>
    <StyledSpinner />
  </StyledSpinnerWrap>
)
