import { colors } from '../colors'
import { border } from '../border'

export const statusFlag = {
  lightTextColor: colors.white,
  darkTextColor: colors.darkGrey,
  greenTextColor: colors.green,
  yellowTextColor: colors.yellow,
  borderStyle: `2px solid ${border.borderColor}`,
  bgColorDefault: colors.blue,
  bgColorSuccess: colors.green,
  bgColorSuccessAlt: colors.lightGreen,
  bgColorError: colors.red,
  bgColorWarning: colors.yellow,
  bgColorWarningAlt: colors.lightYellow,
  bgColorInfo: colors.lightGrey,
  bgColorOther: colors.navy,
  bgColorNew: colors.blue,
  bgColorLight: colors.lightBlue,
  bgColorAlert: colors.orange,
}
