import { Icon } from '.'
import { IconProps } from './types'

export const DateTimeIconSVG = () => (
    <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="m562.5 168.75h-150c-6.6992 0-12.887-3.5742-16.238-9.375-3.3477-5.8008-3.3477-12.949 0-18.75 3.3516-5.8008 9.5391-9.375 16.238-9.375h150c6.6992 0 12.887 3.5742 16.238 9.375 3.3477 5.8008 3.3477 12.949 0 18.75-3.3516 5.8008-9.5391 9.375-16.238 9.375z"/>
    <path d="m318.75 262.5c-14.918 0-29.227-5.9258-39.773-16.477-10.551-10.547-16.477-24.855-16.477-39.773v-112.5c0-20.098 10.723-38.664 28.125-48.715 17.402-10.047 38.848-10.047 56.25 0 17.402 10.051 28.125 28.617 28.125 48.715v112.5c0 14.918-5.9258 29.227-16.477 39.773-10.547 10.551-24.855 16.477-39.773 16.477zm0-187.5c-4.9727 0-9.7422 1.9766-13.258 5.4922-3.5156 3.5156-5.4922 8.2852-5.4922 13.258v112.5c0 6.6992 3.5742 12.887 9.375 16.238 5.8008 3.3477 12.949 3.3477 18.75 0 5.8008-3.3516 9.375-9.5391 9.375-16.238v-112.5c0-4.9727-1.9766-9.7422-5.4922-13.258-3.5156-3.5156-8.2852-5.4922-13.258-5.4922z"/>
    <path d="m656.25 262.5c-14.918 0-29.227-5.9258-39.773-16.477-10.551-10.547-16.477-24.855-16.477-39.773v-112.5c0-20.098 10.723-38.664 28.125-48.715 17.402-10.047 38.848-10.047 56.25 0 17.402 10.051 28.125 28.617 28.125 48.715v112.5c0 14.918-5.9258 29.227-16.477 39.773-10.547 10.551-24.855 16.477-39.773 16.477zm0-187.5c-4.9727 0-9.7422 1.9766-13.258 5.4922-3.5156 3.5156-5.4922 8.2852-5.4922 13.258v112.5c0 6.6992 3.5742 12.887 9.375 16.238 5.8008 3.3477 12.949 3.3477 18.75 0 5.8008-3.3516 9.375-9.5391 9.375-16.238v-112.5c0-4.9727-1.9766-9.7422-5.4922-13.258-3.5156-3.5156-8.2852-5.4922-13.258-5.4922z"/>
    <path d="m956.25 1087.5c-44.754 0-87.676-17.777-119.32-49.426-31.648-31.648-49.426-74.57-49.426-119.32s17.777-87.676 49.426-119.32c31.648-31.648 74.57-49.426 119.32-49.426s87.676 17.777 119.32 49.426c31.648 31.648 49.426 74.57 49.426 119.32-0.050781 44.738-17.844 87.633-49.48 119.27s-74.531 49.43-119.27 49.48zm0-300c-34.809 0-68.195 13.828-92.809 38.441-24.613 24.613-38.441 58-38.441 92.809s13.828 68.195 38.441 92.809c24.613 24.613 58 38.441 92.809 38.441s68.195-13.828 92.809-38.441c24.613-24.613 38.441-58 38.441-92.809-0.039062-34.797-13.879-68.16-38.484-92.766s-57.969-38.445-92.766-38.484z"/>
    <path d="m881.25 131.25h-131.25c-6.6992 0-12.887 3.5742-16.238 9.375-3.3477 5.8008-3.3477 12.949 0 18.75 3.3516 5.8008 9.5391 9.375 16.238 9.375h131.25c14.918 0 29.227 5.9258 39.773 16.477 10.551 10.547 16.477 24.855 16.477 39.773v112.5h-900v-112.5c0-14.918 5.9258-29.227 16.477-39.773 10.547-10.551 24.855-16.477 39.773-16.477h131.25c6.6992 0 12.887-3.5742 16.238-9.375 3.3477-5.8008 3.3477-12.949 0-18.75-3.3516-5.8008-9.5391-9.375-16.238-9.375h-131.25c-24.855 0.03125-48.684 9.918-66.258 27.492-17.574 17.574-27.461 41.402-27.492 66.258v731.25c0.03125 24.855 9.918 48.684 27.492 66.258 17.574 17.574 41.402 27.461 66.258 27.492h591.71c6.6992 0 12.891-3.5742 16.238-9.375 3.3516-5.8008 3.3516-12.949 0-18.75-3.3477-5.8008-9.5391-9.375-16.238-9.375h-591.71c-14.918 0-29.227-5.9258-39.773-16.477-10.551-10.547-16.477-24.855-16.477-39.773v-581.25h900v262.5c0 6.6992 3.5742 12.887 9.375 16.238 5.8008 3.3477 12.949 3.3477 18.75 0 5.8008-3.3516 9.375-9.5391 9.375-16.238v-412.5c-0.03125-24.855-9.918-48.684-27.492-66.258-17.574-17.574-41.402-27.461-66.258-27.492z"/>
    <path d="m487.5 862.5c-3.4023 0.003906-6.7422-0.92188-9.6562-2.6797-8.0078-4.8203-196.59-119.34-196.59-241.07-0.035156-32.145 11.738-63.184 33.09-87.215 21.348-24.031 50.781-39.383 82.703-43.133 31.926-3.7539 64.117 4.3516 90.457 22.777 26.34-18.426 58.531-26.531 90.457-22.777 31.922 3.75 61.355 19.102 82.703 43.133 21.352 24.031 33.125 55.07 33.09 87.215 0 121.73-188.59 236.25-196.59 241.07-2.9141 1.7578-6.2539 2.6836-9.6562 2.6797zm-75-337.5c-24.855 0.03125-48.684 9.918-66.258 27.492-17.574 17.574-27.461 41.402-27.492 66.258 0 78.469 113.91 167.76 168.75 202.91 54.844-35.156 168.75-124.45 168.75-202.91 0.039062-24.273-9.3555-47.617-26.199-65.098-16.844-17.48-39.82-27.73-64.078-28.594-24.262-0.85938-47.906 7.7383-65.949 23.98-3.4375 3.0859-7.8984 4.7969-12.523 4.7969s-9.0859-1.7109-12.523-4.7969c-17.145-15.449-39.398-24.012-62.477-24.039z"/>
    <path d="m1012.5 937.5h-56.25c-4.9727 0-9.7422-1.9766-13.258-5.4922-3.5156-3.5156-5.4922-8.2852-5.4922-13.258v-56.25c0-6.6992 3.5742-12.887 9.375-16.238 5.8008-3.3477 12.949-3.3477 18.75 0 5.8008 3.3516 9.375 9.5391 9.375 16.238v37.5h37.5c6.6992 0 12.887 3.5742 16.238 9.375 3.3477 5.8008 3.3477 12.949 0 18.75-3.3516 5.8008-9.5391 9.375-16.238 9.375z"/>
    <path d="m956.25 1162.5c-61.617 0.03125-120.95-23.285-166.07-65.258-45.109-41.969-72.641-99.473-77.051-160.93-4.4102-61.457 14.633-122.3 53.293-170.28 4.2031-5.2188 10.871-7.7969 17.492-6.7656 6.6211 1.0312 12.188 5.5156 14.605 11.766 2.418 6.25 1.3164 13.312-2.8867 18.531-29.66 36.508-45.785 82.148-45.637 129.19-0.13281 45.867 14.984 90.48 42.977 126.82 27.992 36.34 67.266 62.344 111.65 73.922 44.383 11.582 91.355 8.082 133.54-9.9492 42.176-18.031 77.164-49.566 99.461-89.652 22.293-40.086 30.633-86.445 23.703-131.79-6.9258-45.344-28.73-87.098-61.98-118.7-33.25-31.602-76.059-51.25-121.7-55.863-45.637-4.6133-91.512 6.0742-130.41 30.383-5.6953 3.5508-12.852 3.793-18.773 0.63672s-9.707-9.2305-9.9375-15.938c-0.22656-6.707 3.1406-13.023 8.8359-16.574 42.844-26.777 92.953-39.582 143.39-36.637 50.438 2.9453 98.715 21.488 138.15 53.07s68.09 74.637 81.988 123.21c13.898 48.578 12.359 100.27-4.4062 147.93-16.762 47.66-47.926 88.938-89.172 118.11-41.246 29.18-90.543 44.82-141.07 44.758z"/>
   </svg>
)

export const DateTimeIcon = (props: IconProps) => (
  <Icon {...props}>
    <DateTimeIconSVG />
  </Icon>
)