import { hexToRGBa, lightenDarkenColor } from 'helpers/functions'
import { colors } from '../colors'

export const button = {
  primary: {
    bgColor: colors.earth,
    borderColor: colors.auburn,
    textColor: colors.white,
    subtleBgHover: hexToRGBa(colors.earth, 0.2),
    subtleTxtHover: lightenDarkenColor(colors.earth, -30),
    lightBgColor: hexToRGBa(colors.earth, 0.1),
    lightBgHover: hexToRGBa(colors.earth, 0.2),
    outlineBgHover: hexToRGBa(colors.earth, 0.1),
    filledBgHover: lightenDarkenColor(colors.earth, -30),
  },
  disabled: {
    bgColor: colors.lightGrey,
    borderColor: colors.lightGrey,
    textColor: lightenDarkenColor(colors.midGrey, -30),
    subtleBgHover: hexToRGBa(colors.lightGrey, 0.2),
    subtleTxtHover: lightenDarkenColor(colors.lightGrey, -30),
    lightBgColor: hexToRGBa(colors.lightGrey, 0.1),
    lightBgHover: hexToRGBa(colors.lightGrey, 0.2),
    outlineBgHover: hexToRGBa(colors.lightGrey, 0.1),
    filledBgHover: lightenDarkenColor(colors.lightGrey, -30),
  },
  grey: {
    bgColor: colors.darkGrey,
    borderColor: colors.border,
    textColor: colors.darkGrey,
    subtleBgHover: hexToRGBa(colors.darkGrey, 0.2),
    subtleTxtHover: lightenDarkenColor(colors.darkGrey, -30),
    lightBgColor: hexToRGBa(colors.darkGrey, 0.1),
    lightBgHover: hexToRGBa(colors.darkGrey, 0.2),
    outlineBgHover: hexToRGBa(colors.darkGrey, 0.1),
    filledBgHover: lightenDarkenColor(colors.darkGrey, -30),
  },
  white: {
    bgColor: colors.white,
    borderColor: colors.white,
    textColor: colors.white,
    subtleBgHover: hexToRGBa(colors.white, 0.2),
    subtleTxtHover: lightenDarkenColor(colors.white, -30),
    lightBgColor: hexToRGBa(colors.white, 0.1),
    lightBgHover: hexToRGBa(colors.white, 0.2),
    outlineBgHover: hexToRGBa(colors.white, 0.1),
    filledBgHover: lightenDarkenColor(colors.white, -30),
  },
  subtleBgHover: (col: string) => hexToRGBa(col, 0.2),
  subtleTxtHover: (col: string) => lightenDarkenColor(col, -30),
  lightBgColor: (col: string) => hexToRGBa(col, 0.1),
  lightBgHover: (col: string) => hexToRGBa(col, 0.2),
  outlineBgHover: (col: string) => hexToRGBa(col, 0.1),
  filledBgHover: (col: string) => lightenDarkenColor(col, -30),
}
