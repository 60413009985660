import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'
import { breakpoint } from 'helpers/functions'

export const pStyles = css`
  font-family: ${getCssVar('font.defaultFont')};
  font-size: ${getCssVar('font.size.base')};
  font-weight: ${getCssVar('font.weight.regular')};
  line-height: ${getCssVar('font.lineHeight.xlarge')};
  color: ${getCssVar('body.textColor')};
`

export const StyledP = styled.p`
  ${pStyles}

  ${breakpoint('down', 'md')} {
  font-size: ${getCssVar('font.size.sm')}};
  line-height: ${getCssVar('font.lineHeight.large')}};
`
