import { HeadingLevel } from 'types/styles'
import { StyledH3 } from './styles'

export type H3Props = {
  as?: HeadingLevel
} & React.HTMLAttributes<HTMLHeadingElement>

export const H3 = (props: H3Props) => {
  const { children, ...rest } = props
  return <StyledH3 {...rest}>{children}</StyledH3>
}
