import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'
import { breakpoint, rem } from 'helpers/functions'

export const h2Styles = css`
  font-family: ${getCssVar('font.headingFont')};
  font-size: ${getCssVar('font.size.xl')};
  font-weight: ${getCssVar('font.weight.regular')};
  line-height: ${getCssVar('font.lineHeight.medium')};
  color: ${getCssVar('body.headingColor')};
  letter-spacing: ${rem(1.3)};
  text-align: center;
  width: 100%;

  ${breakpoint('down', 'md')} {
    font-size: ${getCssVar('font.size.lg')};
  }
  
  ${breakpoint('down', 'sm')} {
    font-size: ${getCssVar('font.size.lg')};
  }
`

export const StyledH2 = styled.h2`
  ${h2Styles}
`
