import { rem } from 'helpers/functions'
import { getCssVar } from 'theme'

export type Spacing = keyof typeof spacing

export const spacing = {
  px1: '1px',
  px2: '2px',
  xxs: rem(4),
  xs: rem(8),
  sm: rem(12),
  md: rem(16),
  lg: rem(20),
  xl: rem(24),
  xxl: rem(32),
}

export function getSpacing(value: Spacing | number): string {
  if (typeof value === 'number') {
    return rem(value)
  }

  return getCssVar(`spacing.${value}`)
}
