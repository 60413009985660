import styled, { css } from 'styled-components'

import { breakpoint, rem } from 'helpers/functions'
import { getCssVar } from 'theme'

import { CarouselSlideProps, CarouselSlidesProps, NavButtonProps, NavControllerProps } from './types'

export const StyledCarouselContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`

export const StyledNavController = styled.div<NavControllerProps>`
  width: 1px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;

  ${(props) =>
    props.left &&
    css`
      left: 0;
    `}

  ${(props) =>
    props.right &&
    css`
      right: 0;
    `}

  ${breakpoint('down', 'md')} {
    top: 32px;
    left: inherit;

    ${(props) =>
      props.right &&
      css`
        right: 0;
      `}
  }
`

export const StyledNavButton = styled.div<NavButtonProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-left: ${props => props.previous ? `-${rem(100)}` : props.next ? rem(50) : 'auto' };
  position: absolute;
  transition: ${getCssVar('transitions.smooth')};
  width: 50px;
  z-index: 1;

  i {
    color: ${getCssVar('body.linkColor')};
    fill: ${getCssVar('body.linkColor')};
  }

  &:hover {
    transform: scale(1.4);
  }

  ${breakpoint('down', 'md')} {
      width: 30px;

    ${props => props.previous && css`margin-left: -${rem(30)};`};
    ${props => props.next && css`margin-right: -${rem(30)};`};

    ${props => props.next && css`
      right: 0;
      `}
  }

  ${props => props.slideCount <= 1 && css` display: none; `}
`

export const CarouselSlide = styled.div<CarouselSlideProps>`
  box-sizing: border-box;
  opacity: ${(props) => (props.active ? 1 : 0)};
  display: inline-block;
  padding: 1rem;
  transition: all 0.5s ease;
  width: ${(props) => 100 / props.slideCount}%;

  > div {
    width: 100%;
  }

  > img {
    height: auto;
    width: 100%;
  }

  ${breakpoint('down', 'md')} {
    padding: 0;
  }
`

export const CarouselSlides = styled.div<CarouselSlidesProps>`
  box-sizing: border-box;
  display: block;
  ${(props) =>
    props.currentSlide &&
    css`
      transform: translateX(-${props.currentSlide * (100 / props.slideCount)}%);
    `};
  transition: all 0.5s ease;
  min-width: ${(props) => (100 / props.showCount) * props.slideCount}%;

  ${breakpoint('down', 'sm')} {
    min-width: ${(props) => 100 * props.slideCount}%;
  }
`