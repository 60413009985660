import { hexToRGBa, lightenDarkenColor } from 'helpers/functions'
import { colors } from './colors'

export const body = {
  backgroundColor: colors.white,
  textColor: colors.slate,
  textColorAlt: colors.darkGrey,
  textColorDark: colors.slate,
  textColorLight: colors.darkGrey,
  textColorExtraLight: colors.midGrey,
  headingColor: colors.slate,
  headingBorderColor: colors.midGrey,
  linkColor: colors.auburn,
  linkHoverColor: lightenDarkenColor(colors.auburn, -40),
  boxShadowColor: hexToRGBa(colors.border, 0.5),
}
