import styled, { css } from 'styled-components'
import { breakpoint, rem } from 'helpers/functions'

export const StyledSection = styled.div<Partial<{ fullHeight: boolean }>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1920px;
  padding: ${rem(80)} ${rem(30)};
  position: relative;
  width: 100%;

  H3 {
    text-align: center;
  }

  ${props => props.fullHeight && css`
      height: 100vh;
      min-height: 100vh;
    `}

  ${breakpoint('up', 'md')} {
    padding: ${rem(100)} ${rem(40)} ${rem(110)};
  }

  ${breakpoint('up', 'lg')} {
    padding: ${rem(200)} ${rem(40)} ${rem(220)};
  }
`

export const SectionContent = styled.div<{ fullWidth?: boolean }>`
  margin: 0 auto;
  max-width: ${props => props.fullWidth ? '100%' : '1240px'};
  position: relative;
  width: 100%;
  z-index: 10;
`