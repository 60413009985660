import { takeRight } from 'lodash'
import { useEffect, useState } from 'react'

import { PageNextIcon } from 'components/icons/PageNextIcon'
import { PagePreviousIcon } from 'components/icons/PagePreviousIcon'
import { useBreakpoint } from 'hooks/useBreakpoint'

import { CarouselSlide, CarouselSlides, StyledCarouselContainer, StyledNavButton, StyledNavController } from './styles'
import { CarouselProps } from './types'

export const Carousel = (props: CarouselProps) => {
  const { slides, showCount } = props
  const slideCount = slides.length
  const stepCount = slides.length - 1

  const [show, setShow] = useState(showCount)
  const [initSlides, setInitSlides] = useState(Array.from(Array(show).keys()))
  const [lastSlides, setLastSlides] = useState(takeRight(Array.from(Array(slideCount).keys()), show))
  const [activeSlides, setActiveSlides] = useState(initSlides)

  const isDesktop = useBreakpoint('up', 'md')
  const isTablet = useBreakpoint('down', 'md')
  const isMobile = useBreakpoint('down', 'sm')

  useEffect(() => {
    if (isMobile) {
      setShow(1)
      setInitSlides(Array.from(Array(1).keys()))
      setLastSlides(takeRight(Array.from(Array(slideCount).keys()), 1))
    }

    if (isTablet) {
      setShow(1)
      setInitSlides(Array.from(Array(1).keys()))
      setLastSlides(takeRight(Array.from(Array(slideCount).keys()), 1))
    }

    if (isDesktop) {
      setShow(showCount)
      setInitSlides(Array.from(Array(showCount).keys()))
      setLastSlides(takeRight(Array.from(Array(slideCount).keys()), showCount))
    }
  }, [isTablet, isMobile, isDesktop])

  const allSlides = slides.map((slide: any, index: number) => (
    <CarouselSlide key={index} slideCount={slideCount} active={activeSlides.includes(index)}>
      {slide}
    </CarouselSlide>
  ))

  return (
    <StyledCarouselContainer>
      <StyledNavController left>
        <StyledNavButton
          showCount={show}
          slideCount={slideCount}
          previous
          onClick={() => {
            const newSlides = [...activeSlides]
            const firstSlide = newSlides[0]
            const newFirstSlide = firstSlide - 1
            newSlides.pop()
            newSlides.unshift(newFirstSlide)
            setActiveSlides(firstSlide <= 0 ? lastSlides : newSlides)
          }}
        >
          <PagePreviousIcon />
        </StyledNavButton>
      </StyledNavController>
      <CarouselSlides currentSlide={activeSlides[0]} slideCount={slideCount || 0} showCount={show}>
        {allSlides}
      </CarouselSlides>
      <StyledNavController right>
        <StyledNavButton
          showCount={show}
          slideCount={slideCount}
          next
          onClick={() => {
            const newSlides = [...activeSlides]
            const lastSlide = newSlides.slice(-1).pop() || 0
            const newLastSlide = lastSlide + 1
            newSlides.shift()
            newSlides.push(newLastSlide)
            setActiveSlides(lastSlide >= stepCount ? initSlides : newSlides)
          }}
        >
          <PageNextIcon />
        </StyledNavButton>
      </StyledNavController>
    </StyledCarouselContainer>
  )
}