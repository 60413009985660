import { CrossIcon } from 'components/icons/CrossIcon'
import { Button } from 'components/inputs/Button'

import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
  StyledClose,
  StyledExit,
  StyledStickyFooter,
} from './styles'
import { ModalProps, StickyModalHeaderProps } from './types'

export const Modal = ({
  children = <></>,
  trigger = null,
  hideTrigger,
  triggerLabel = 'Open Modal',
  triggerLabelFullWidth = false,
  maxWidth = 'sm',
  fullWidth,
  className,
  onClose,
  isFullScreen,
  useStickyFooter,
  renderCustomHeader,
  ...rest
}: ModalProps) => {
  const CloseComponent = (
    <StyledClose data-testid="closeModal" isFullScreen={isFullScreen}>
      <DialogClose asChild>
        <StyledExit
          onClick={typeof onClose === 'function' ? () => onClose() : undefined}
          isFullScreen={isFullScreen}
          useStickyFooter={useStickyFooter}
        >
          <CrossIcon />
        </StyledExit>
      </DialogClose>
    </StyledClose>
  )

  return (
    <DialogRoot {...rest}>
      {!hideTrigger && trigger ? (
        trigger
      ) : !hideTrigger && triggerLabel !== '' ? (
        <DialogTrigger>
          <Button as="span" fullWidth={triggerLabelFullWidth} label={triggerLabel} color="primary" variant="fill" />
        </DialogTrigger>
      ) : null}
      <DialogContent
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        className={className}
        isFullScreen={isFullScreen}
        useStickyFooter={useStickyFooter}
        renderCustomHeader={renderCustomHeader}
      >
        {renderCustomHeader ? renderCustomHeader(CloseComponent) : CloseComponent}
        {children}
      </DialogContent>
    </DialogRoot>
  )
}

export const ModalContent = DialogContent
export const ModalRoot = DialogRoot
export const ModalTitle = DialogTitle
export const ModalTrigger = DialogTrigger
export const ModalExit = StyledExit
export const ModalClose = StyledClose
export const ModalDescription = DialogDescription

export const StickyModalHeader = ({ title, children }: StickyModalHeaderProps) => {
    return (
      <StyledStickyFooter>
        <ModalTitle>{title}</ModalTitle>
        {children}
      </StyledStickyFooter>
    )
  }

