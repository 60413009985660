import React, { useEffect } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { isloggedIn } from 'helpers/auth'

type ProtectedRouteProps = {
  component: React.ComponentType<any>
} & RouteProps

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const navigate = useNavigate()
  const loggedIn = isloggedIn()

  useEffect(() => {
    if (!loggedIn) {
      navigate('/login')
    }
  }, [navigate, loggedIn])

  return loggedIn ? <Component {...rest} /> : null
}

export default ProtectedRoute
