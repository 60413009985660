import { rem } from 'helpers/functions'
import { colors } from './colors'

export const border = {
  borderColor: colors.border,
  borderRadius: rem(4),
  borderRadiusSize: {
    sm: rem(4),
    md: rem(8),
    lg: rem(12),
  },
  borderStyle: 'solid',
  borderWidth: '1px',
  common: `var(--border-border-width) var(--border-border-style) var(--border-border-color)`,
}
