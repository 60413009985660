import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { InputField } from 'components/inputs/InputField'
import { Space } from 'components/layout/Space'
import { Button } from 'components/inputs/Button'
import { H3 } from 'components/typography/H3'
import { P } from 'components/typography/P'
import Countdown from 'pages/components/countdown'
import PageContent from 'components/layout/pageContent'
import { Label } from 'components/inputs/Label'
import { HidePasswordIcon } from 'components/icons/HidePassword'
import { ShowPasswordIcon } from 'components/icons/ShowPassword'
import { StyledForm, StyledPageSection } from './styles'

const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [showPassword, setShowPassowrd] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password,
      })

      localStorage.setItem('token', response.data.token)
      navigate('/')
      setLoading(false)
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data.error || 'An error occurred during login.')
      } else if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('An unknown error occurred.')
      }
      setLoading(false)
    }
  }

  const requestOtp = async (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/request-otp`, {
        email
      })
      const params = new URLSearchParams({ email }).toString();
      navigate(`/otp?${params}`)
      setLoading(false)
    } catch(err) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data.error || 'An error occurred during login.')
      } else if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('An unknown error occurred.')
      }
      setLoading(false)
    }
  }

  return (
    <PageContent>
      <StyledPageSection>
        <StyledForm>
          <form onSubmit={handleLogin}>
            <H3>Hello!</H3>
            <P>Use your first name as your password to login.</P>
            <Space align="h" size="lg" />
            <InputField
              placeholder="Enter your email"
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
              onEnterKey={(e) => {
                const val = (e.target as HTMLInputElement).value
              }}
              error={!!error && error.includes('email')}
              errorMessage={error}
              type="email"
            />
            <Space align="h" size="sm" />
            <InputField
              placeholder="Enter your password"
              onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
              onEnterKey={(e) => {
                const val = (e.target as HTMLInputElement).value
                if (val !== '' && val.length > 3) handleLogin(e)
              }}
              error={!!error && error.includes('password')}
              errorMessage={error}
              type={showPassword ? 'text' : 'password'}
              icon={showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
              iconOnClick={() => setShowPassowrd(!showPassword)}
            />
            <Space align="h" size="sm" />
            {!!error && error.includes('password') && (
              <>
                <Space align="h" size="xl" />
                <Label>Having trouble logging in?</Label>
                <Button fullWidth onClick={requestOtp} isLoading={loading}>
                  Send One-time Passcode
                </Button>
                <Space align="h" size="xl" />
              </>
            )}
            <Button type="submit" fullWidth isLoading={loading}>
              Login
            </Button>
            <Countdown />
          </form>
        </StyledForm>
      </StyledPageSection>
    </PageContent>
  )
}

export default Login
