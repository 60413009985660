import styled, { keyframes } from 'styled-components'

import * as ToastP from '@radix-ui/react-toast'

import { rem } from 'helpers/functions'
import { getCssVar } from 'theme'
import { getSpacing } from 'theme/spacing'

const hide = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + ${getSpacing('xl')}));
  }
  to {
    transform: translateX(0);
  }
`
const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + ${getSpacing('xl')}));
  }
`

export const StyledViewport = styled(ToastP.Viewport)`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: ${getSpacing('sm')};
  width: ${rem(390)};
  min-width: 240px;
  max-width: 80%;
  margin: ${getSpacing('xl')};
  list-style: none;
  z-index: 9999999999999;
  outline: none;
  top: 65px;
  right: 0px;
`

export const StyledClose = styled(ToastP.Close)`
  position: absolute;
  top: 0px;
  right: 0px;
`

export const StyledToast = styled(ToastP.Root)`
  background-color: ${getCssVar('toast.bgColor')};
  border-radius: ${getCssVar('border.borderRadius')};
  column-gap: ${getSpacing('md')};
  align-items: center;
  box-shadow: ${getCssVar('toast.boxShadow')};
  z-index: 9999999999999999;

  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: ${slideIn} 400ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: ${hide} 350ms ease-in;
    }
    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
    &[data-swipe='end'] {
      animation: ${swipeOut} 250ms ease-out;
    }
  }
`

export const StyledExit = styled.button`
  display: flex;
  &:hover {
    background-color: ${getCssVar('modal.hoverCloseBgColor')};
    border-radius: ${getCssVar('border.borderRadius')};
    cursor: pointer;
  }
`

export const StyledContents = styled.div`
  line-height: 1.1;
`
