import styled, { css, keyframes } from 'styled-components'

import * as DialogPrimitive from '@radix-ui/react-dialog'

import { rem } from 'helpers/functions'
import { getCssVar } from 'theme'
import { getSpacing } from 'theme/spacing'

import { ModalProps } from './types'

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: ${getCssVar('modal.overlay.opacity')};
  }
`

const contentShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(.96);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const StyledOverlay = styled(DialogPrimitive.Overlay)`
  background-color: ${getCssVar('modal.overlay.bgColor')};
  position: fixed;
  inset: 0px;
  opacity: 0.4;
  z-index: 9998;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: forwards;
  }
`

const StyledContent = styled(DialogPrimitive.Content)<Partial<ModalProps>>`
  z-index: ${(props) => (props.isFullScreen ? '9900' : '9999')};
  background-color: ${getCssVar('modal.bgColor')};
  border: ${getCssVar('modal.border')};
  border-radius: ${getCssVar('modal.borderRadius')};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: calc(50% - ${rem(32)});
  left: calc(50% - ${rem(32)});
  transform: translate(-50%, -50%);
  margin: ${rem(32)};
  max-width: calc(100% - 4rem);
  width: calc(100% - 4rem);
  overflow: auto;

  ${(props) => {
    if (typeof props?.maxWidth === 'number') {
      return css`
        max-width: ${rem(props.maxWidth)};
      `
    }
    switch (props.maxWidth) {
      case 'xxs':
        return css`
          max-width: ${rem(444)};
        `
      case 'xs':
        return css`
          max-width: ${rem(516)};
        `
      case 'sm':
        return css`
          max-width: ${rem(600)};
        `
      case 'md':
        return css`
          max-width: ${rem(900)};
        `
      case 'lg':
        return css`
          max-width: ${rem(1200)};
        `
      case 'xl':
        return css`
          max-width: ${rem(1536)};
        `
      default:
        return css`
          max-width: none;
        `
    }
  }}

  ${(props) =>
    props.fullWidth &&
    css`
      width: calc(100% - ${rem(64)});
    `}

  max-height: calc(100% - ${rem(64)});
  padding: ${getSpacing('lg')};

  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:focus: {
    outline: none;
  }

  ${(props) =>
    props.isFullScreen &&
    css`
      background-color: ${getCssVar('colors.black')};
      border-radius: 0;
      box-shadow: none;
      height: 100vh;
      max-height: 100%;
      max-width: 100%;
      padding: 0;
      width: 100vw;
      overflow: hidden;

      > img {
        max-width: 100%;
      }
    `}

  ${(props) =>
    props.useStickyFooter &&
    css`
      padding-bottom: 0;
    `}

  ${(props) =>
    props.renderCustomHeader &&
    css`
      padding-top: 0;
    `}
`

export const StyledInnerContent = styled.div`
  box-sizing: border-box;
  height: calc(100% - ${rem(120)});
  max-height: 100%;
  overflow-y: scroll;
  width: 100%;
`

const { Portal } = DialogPrimitive

const Content = ({
  children,
  maxWidth,
  fullWidth,
  isFullScreen,
  ...props
}: DialogPrimitive.DialogContentProps & Partial<ModalProps>) => {
  return (
    <Portal>
      {!isFullScreen && <StyledOverlay />}
      <StyledContent maxWidth={maxWidth} fullWidth={fullWidth} isFullScreen={isFullScreen} {...props}>
        {children}
      </StyledContent>
    </Portal>
  )
}

const StyledTitle = styled(DialogPrimitive.Title)`
  font-family: ${getCssVar('font.defaultFont')};
  font-size: ${getCssVar('font.size.lg')};
  font-weight: ${getCssVar('font.weight.semiBold')};
  line-height: 1;
  color: ${getCssVar('body.headingColor')};
`

const StyledDescription = styled(DialogPrimitive.Description)``

export const StyledModalContent = styled.div`
  padding: ${rem(20)} ${rem(0)};
`

export const StyledExit = styled.button<Partial<{ isFullScreen: boolean; useStickyFooter: boolean }>>`
  cursor: pointer;
  display: flex;
  &:hover {
    background-color: ${getCssVar('modal.hoverCloseBgColor')};
    border-radius: ${getCssVar('border.borderRadius')};
  }

  ${(props) =>
    props.isFullScreen &&
    css`
      svg {
        color: ${getCssVar('stepper.color')};
      }
    `}

  ${(props) =>
    props.useStickyFooter &&
    css`
      position: absolute;
      right: -${rem(20)};
    `}
`

export const StyledClose = styled.div<Partial<{ isFullScreen: boolean; useStickyFooter: boolean }>>`
  cursor: pointer;
  position: absolute;
  right: ${rem(4)};
  top: ${getSpacing('xxs')};
  z-index: 13;

  ${(props) =>
    props.isFullScreen &&
    css`
      right: ${getSpacing('md')};
      top: ${getSpacing('md')};
    `}

  ${(props) =>
    props.useStickyFooter &&
    css`
      right: -${rem(20)};
    `}
`

export const DialogTrigger = styled(DialogPrimitive.Trigger)`
  align-items: flex-start;
  align-self: flex-start;
`

export const StyledStickyFooter = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding-top: ${getSpacing('lg')};
  padding-bottom: ${getSpacing('xs')};
  background: ${getCssVar('modal.bgColor')};
  z-index: 1;
`

export const DialogRoot = DialogPrimitive.Root
export const DialogContent = Content
export const DialogTitle = StyledTitle
export const DialogDescription = StyledDescription
export const DialogClose = DialogPrimitive.Close
