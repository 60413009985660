import get from 'lodash/get'
import { camelCaseToSnakeCase } from 'helpers/functions'
import { colors } from './colors'
import { body } from './body'
import { border } from './border'
import { font } from './fonts'
import { boxShadow } from './boxShadow'
import { device } from './device'
import { transitions } from './transitions'
import { spacing } from './spacing'
import { components } from './components'

export type Theme = {
  [key: string]: Theme | string | number
}

export type ThemeMode = 'light' | 'dark'

type ThemePathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, unknown>
    ? T[K] extends ArrayLike<unknown>
      ? K | `${K}.${ThemePathImpl<T[K], Exclude<keyof T[K], keyof unknown[]>>}`
      : K | `${K}.${ThemePathImpl<T[K], keyof T[K]>}`
    : K
  : never

type ThemePath<T> = ThemePathImpl<T, keyof T> | keyof T

export const theme = {
  colors,
  body,
  border,
  boxShadow,
  font,
  device,
  transitions,
  spacing,
  ...components,
}

export function generateCssVars(themeMode?: ThemeMode): string {
  function makeCssVars(theme: any, keys: string[] = [], cssVars: { [key: string]: string | number } = {}) {
    theme &&
      Object.entries(theme).forEach(([key, value]) => {
        const allKeys = [...keys, camelCaseToSnakeCase(key)]
        if (typeof value === 'string' || typeof value === 'number') {
          cssVars[allKeys.join('-')] = value
          return
        }
        makeCssVars(value, allKeys, cssVars)
      })

    return cssVars
  }

  const evaluatedTheme: { [key: string]: any } = {}

  Object.entries(theme).forEach(([key, value]) => {
    if (key === 'colors') return (evaluatedTheme[key] = { ...value })
    switch (themeMode) {
      case 'dark':
        evaluatedTheme[key] = { ...value, ...(value as any)['dark'] }
        delete evaluatedTheme[key]['dark']
        break
      case 'light':
      default:
        evaluatedTheme[key] = { ...value }
        delete evaluatedTheme[key]['dark']
    }
  })

  const allCssVars = makeCssVars(evaluatedTheme)

  return Object.entries(allCssVars)
    .map(([key, value]) => `--${key}: ${value}`)
    .join(';')
    .concat(';')
}

export function getCssVar<P extends ThemePath<typeof theme>>(path: P, withFallback = false) {
  const pathKeys = (path as string).split('.').map((p) => camelCaseToSnakeCase(p))
  return `var(--${pathKeys.join('-')}${withFallback ? `, ${get(theme, path)}` : ''})`
}

export function cl(...args: (string | undefined | null | boolean)[]) {
  return args.filter((a) => !!a).join(' ')
}
