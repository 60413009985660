import styled, { css } from 'styled-components'
import { rem } from 'helpers/functions'
import { IconProps } from './types'

export const StyledIcon = styled.i<Partial<IconProps>>`
  display: inline-flex;
  user-select: none;
  color: inherit;

  & > svg,
  & > i {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  ${(props) =>
    typeof props.size === 'number' &&
    css`
      width: ${rem(props.size)};
      height: ${rem(props.size)};
    `}
`
