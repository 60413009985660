import { Icon } from '.'
import { IconProps } from './types'

export type DirectionProps = { direction: 'up' | 'down' | 'left' | 'right' | number }
export type CustomProps = DirectionProps & IconProps & { chevronType?: number }

export const ChevronIconSVG = (props: DirectionProps) => {
  const { direction = 'down' } = props
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      {direction === 'up' && <path d="M12.1667 19L16 15.2703L19.8333 19L21 17.8649L16 13L11 17.8649L12.1667 19Z" />}
      {direction === 'down' && <path d="M19.8333 13L16 16.7297L12.1667 13L11 14.1351L16 19L21 14.1351L19.8333 13Z" />}
      {direction === 'left' && <path d="M19 19.8333L15.2703 16L19 12.1667L17.8649 11L13 16L17.8649 21L19 19.8333Z" />}
      {direction === 'right' && <path d="M13 12.1667L16.7297 16L13 19.8333L14.1351 21L19 16L14.1351 11L13 12.1667Z" />}
    </svg>
  )
}

export const ChevronIconSVG2 = (props: DirectionProps) => {
  const { direction = 'down' } = props
  let degree = typeof direction === 'number' ? direction : 0

  switch (direction) {
    case 'down':
      degree = 0
      break
    case 'left':
      degree = 90
      break
    case 'up':
      degree = 180
      break
    case 'right':
      degree = 270
      break
  }

  return (
    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
      <g transform={`rotate(${degree}deg)`}>
        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
      </g>
    </svg>
  )
}

export const ChevronIcon = (props: CustomProps) => {
  const { direction, chevronType = 0, ...restProps } = props
  let SVGComponent = ChevronIconSVG

  if (chevronType === 1) {
    SVGComponent = ChevronIconSVG2
  }

  return (
    <Icon {...restProps}>
      <SVGComponent direction={direction} />
    </Icon>
  )
}
