import styled from 'styled-components'

import { ThemeMode } from '.'

export type DecoratorProps = {
  noMargin?: boolean
  noPadding?: boolean
  children?: React.ReactNode
  themeMode?: ThemeMode
}

export const Decorator = ({ children, ...restProps }: DecoratorProps) => {
  return (
    <>
      <StyledDecorator {...restProps}>{children}</StyledDecorator>
    </>
  )
}

const StyledDecorator = styled.div<Partial<DecoratorProps>>`
  // margin: ${(props) => (props.noMargin ? '0' : '40px')};
  // padding: ${(props) => (props.noPadding ? '0' : '40px')};
    width: 100%;
`
