import { rem } from 'helpers/functions'
import { colors } from '../colors'
import { border } from '../border'
import { font } from '../fonts'

export const selectAutoComplete = {
  labelColor: colors.slate,
  control: {
    background: colors.white,
    border: border.common,
    borderBottom: border.common,
    borderRadius: border.borderRadius,
    textColor: colors.slate,
  },
  indicator: {
    color: colors.darkGrey,
    hover: colors.slate,
  },
  option: {
    background: colors.white,
    textColor: colors.slate,
    hover: colors.lightGrey,
  },
  multiValue: {
    background: colors.slate,
    textColor: colors.white,
    paddingLeft: rem(4),
    paddingRight: rem(4),
    borderRadius: rem(12),
    fontFamily: font.defaultFont,
    fontSize: font.size.xxs,
    fontWeight: font.weight.medium,
  },
  multiValueRemove: {
    background: colors.white,
    marginLeft: rem(5),
    borderRadius: '50%',
    height: rem(16),
    width: rem(16),
    display: 'flex',
    justifyContent: 'center',
  },
  fontSize: {
    label: {
      xs: font.size.tiny,
      sm: font.size.xxs,
      md: font.size.xs,
      lg: font.size.sm,
      xl: font.size.md,
    },
  },
}
