import { Icon } from '.'
import { IconProps } from './types'

export const PagePreviousIconSVG = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="M20.547 9.88 18.667 8l-8 8 8 8 1.88-1.88L14.44 16l6.107-6.12Z" />
  </svg>
)

export const PagePreviousIcon = (props: IconProps) => (
  <Icon {...props}>
    <PagePreviousIconSVG />
  </Icon>
)