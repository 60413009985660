import React from 'react'
import { cl } from 'theme'
import { StyledButton, StyledLoadingRing } from './styles'
import { ButtonLinkProps, ButtonProps } from './types'

// For default Button component, use Button
// For url Button component, use ButtonLink and add href.

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = 'button',
      className,
      label = '',
      color = 'primary',
      variant = 'default',
      size = 'sm',
      icon,
      iconPlacement = 'left',
      children,
      isLoading,
      ...restProps
    },
    ref
  ) => (
    <StyledButton
      ref={ref}
      as={as}
      className={cl('Button', className)}
      color={color}
      variant={variant}
      size={size}
      iconPlacement={iconPlacement}
      {...restProps}
    >
      {isLoading ? (
        <StyledLoadingRing size={size} />
      ) : (
        <>
          {icon && <span className="button-icon">{icon}</span>}
          <span className="button-label">{children || label}</span>
        </>
      )}
    </StyledButton>
  )
)

// eslint-disable-next-line react/display-name
export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      as = 'a',
      className,
      label = '',
      color = 'primary',
      variant = 'default',
      size = 'sm',
      icon,
      iconPlacement = 'left',
      children,
      isLoading,
      ...restProps
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        as={as}
        className={cl('Button', className)}
        color={color}
        variant={variant}
        size={size}
        iconPlacement={iconPlacement}
        {...restProps}
      >
        {isLoading ? (
          <StyledLoadingRing size={size} />
        ) : (
          <>
            {icon && <span className="button-icon">{icon}</span>}
            {(children || label) && <span className="button-label">{children || label}</span>}
          </>
        )}
      </StyledButton>
    )
  }
)
