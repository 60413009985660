import styled from 'styled-components'
import { rem } from 'helpers/functions'
import PageSection from 'components/layout/pageSection'
import { getCssVar } from 'theme'

export const StyledForm = styled.div`
  margin: ${rem(60)} auto 0;
  max-width: ${rem(400)};
  width: 100%;

  > form {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: ${rem(20)};
    text-align: left;
    width: 100%;
    > div,
    h3 {
      width: 100%;
    }
    p {
      font-size: ${getCssVar('font.size.sm')};
      text-align: center;
      width: 100%;
    }
  }
`

export const StyledPageSection = styled(PageSection)`
  padding-top: 0 !important;
  width: 100%;
`
