import { Link } from 'react-router-dom'
import PageLayout from 'components/layout/pageLayout'
import PageHeader from 'components/layout/pageHeader'
import { P } from 'components/typography/P'
import { Space } from 'components/layout/Space'
import { Button } from 'components/inputs/Button'

function NoMatch() {
  return (
    <PageLayout>
      <PageHeader fullHeight heading="Uh Oh" subHeading={(
          <>
            <Space align="h" size="lg" />
            <P>The page you're looking for does not exist.</P>
            <Space align="h" size="xl" />
            <Link to="/"><Button>Go home</Button></Link>
          </>
        )} />
    </PageLayout>
  )
}

export default NoMatch
