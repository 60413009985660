import { mapBreakpointValues } from 'helpers/functions'
import { BreakpointDirectionProps, BreakpointProps } from 'types/layout'
import useWindowDimensions from './useWindowDimensions'

export function useBreakpoint(direction: BreakpointDirectionProps, breakpoint: BreakpointProps | number) {
  const { width } = useWindowDimensions()
  breakpoint = mapBreakpointValues(breakpoint)

  return direction === 'down' ? width <= breakpoint : width >= breakpoint
}
