import React from 'react'
import styled, { css } from 'styled-components'
import { Toast, ToastProvider } from 'components/display/toast'
import { TickIcon } from 'components/icons/TickIcon'
import { WarningIcon } from 'components/icons/WarningIcon'
import { getCssVar } from 'theme'
import { getSpacing } from 'theme/spacing'
import { rem } from 'helpers/functions'
import { ToastOptions, useToastStore } from 'store/toast'

const StyledContainer = styled.div`
  display: flex;
`

const StyledIcon = styled.div<Partial<ToastOptions>>`
  background-color: ${(props) => (props.success ? getCssVar('colors.green') : getCssVar('colors.red'))};
  border-radius: ${getCssVar('border.borderRadius')};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(50)};
  & i svg {
    color: ${getCssVar('colors.white')};
  }

  ${(props) =>
    props.success &&
    css`
      background-color: ${getCssVar(props.success === true ? 'colors.green' : 'colors.red')};
    `}
`

const StyledContents = styled.div`
  font-size: ${getCssVar('font.size.base')};
  margin: ${getSpacing('xl')};
`

export const ToastAlert = () => {
  const { toasts } = useToastStore()

  return (
    <ToastProvider>
      {toasts.map((toast: ToastOptions) => (
        <Toast duration={3000} key="toastKey">
          <StyledContainer>
          <StyledIcon success={toast.success}>
              {toast.success ? <TickIcon /> : <WarningIcon />}
          </StyledIcon>
          <StyledContents>
              <b>{toast.success ? 'Success' : 'Error'}: </b>
              {toast.message}
          </StyledContents>
          </StyledContainer>
        </Toast>
      ))}
    </ToastProvider>
  )
}
