import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { InputField } from 'components/inputs/InputField'
import { Space } from 'components/layout/Space'
import { Button } from 'components/inputs/Button'
import { H3 } from 'components/typography/H3'
import Countdown from 'pages/components/countdown'
import PageContent from 'components/layout/pageContent'
import { Label } from 'components/inputs/Label'
import { HidePasswordIcon } from 'components/icons/HidePassword'
import { ShowPasswordIcon } from 'components/icons/ShowPassword'
import { StyledForm, StyledPageSection } from './styles'

const OTP: React.FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('email');
  const [email, setEmail] = useState<string>(query || '')
  const [otp, setOtp] = useState<string>('')
  const [showPassword, setShowPassowrd] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/verify-otp`, {
        email,
        otp,
      })

      localStorage.setItem('token', response.data.token)
      navigate('/')
      setLoading(false)
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data.error || 'An error occurred during login.')
      } else if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('An unknown error occurred.')
      }
      setLoading(false)
    }
  }

  const requestOtp = async (e: React.MouseEvent) => {
    e.preventDefault()
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/request-otp`, {
        email
      })
      const params = new URLSearchParams({ email }).toString();
      navigate(`/otp?${params}`)
    } catch(err) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data.error || 'An error occurred during login.')
      } else if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('An unknown error occurred.')
      }
    }
  }

  return (
    <PageContent>
      <StyledPageSection>
        <StyledForm>
          <form onSubmit={handleLogin}>
            <H3>Login with OTP</H3>
            <Space align="h" size="lg" />
            <InputField
              placeholder="Enter your email"
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
              onEnterKey={(e) => {
                const val = (e.target as HTMLInputElement).value
              }}
              defaultValue={email}
              value={email}
              error={!!error && error.includes('email')}
              errorMessage={error}
            />
            <Space align="h" size="sm" />
            <InputField
              placeholder="Enter your one-time passcode"
              onChange={(e) => setOtp((e.target as HTMLInputElement).value)}
              onEnterKey={(e) => {
                const val = (e.target as HTMLInputElement).value
                if (val !== '' && val.length > 3) handleLogin(e)
              }}
              error={!!error && error.includes('password')}
              errorMessage={error}
              type={showPassword ? 'text' : 'password'}
              icon={showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
              iconOnClick={() => setShowPassowrd(!showPassword)}
            />
            <Space align="h" size="sm" />
            <Button type="submit" fullWidth isLoading={loading}>
              Login
            </Button>
            {!!error && (
              <>
                <Space align="h" size="xl" />
                <Label>Having trouble logging in?</Label>
                <Button fullWidth onClick={requestOtp}>
                  Send One-time Passcode
                </Button>
                <Space align="h" size="xl" />
              </>
            )}
            <Countdown />
          </form>
        </StyledForm>
      </StyledPageSection>
    </PageContent>
  )
}

export default OTP
