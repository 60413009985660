import React, { useState } from 'react'
import 'react-dates/initialize'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import moment, { Moment } from 'moment'
import { GlobalStyles } from './styles'

interface DateRangePickerProps {
  initialStartDate?: Moment
  initialEndDate?: Moment
  onDatesChange: (startDate: Moment | null, endDate: Moment | null) => void
}

export const DateRangePickerComponent: React.FC<DateRangePickerProps> = ({
  initialStartDate,
  initialEndDate,
  onDatesChange,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(initialStartDate || null)
  const [endDate, setEndDate] = useState<Moment | null>(initialEndDate || null)
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null
    endDate: Moment | null
  }) => {
    setStartDate(startDate)
    setEndDate(endDate)
    onDatesChange(startDate, endDate)
  }

  return (
    <>
      <GlobalStyles />
      <DateRangePicker
        startDate={startDate}
        startDateId="start_date_id" // unique ID for the start date input
        endDate={endDate}
        endDateId="end_date_id" // unique ID for the end date input
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        numberOfMonths={1}
        isOutsideRange={() => false} // Allow all dates to be selectable
        showClearDates={true} // Allows clearing the selected dates
      />
    </>
  )
}

