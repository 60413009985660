import styled, { css } from 'styled-components'
import { breakpoint, rem } from 'helpers/functions'
import { getCssVar } from 'theme'
import { H3 } from 'components/typography/H3'
import { P } from 'components/typography/P'
import PageSection from 'components/layout/pageSection'

export const TimeLine = styled(PageSection)`
    margin: 0 auto;
    overlfow: hidden;
    padding: ${rem(80)} 0 ${rem(400)};
    position: relative;

    h3 {
        font-size: ${rem(30)};
        line-height: ${getCssVar('font.lineHeight.regular')};
        text-align: left;

        ${breakpoint('down', 'lg')} {
            font-size: ${rem(24)};
        }

        ${breakpoint('down', 'md')} {
            font-size: ${rem(18)};
        }
    }

`

export const SrcollLine = styled.div`
    background: ${getCssVar('body.headingColor')};
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0; bottom: 0;
    transition: height 300ms linear;
    width: 1px;
`

export const Time = styled(P)`
    font-weight: ${getCssVar('font.weight.bold')};
    position: relative;
`

export const Dot = styled.div<{ active: boolean }>`
    background-color: ${getCssVar('body.textColorAlt')};
    border-radius: 50%;
    height: ${rem(11)};
    position: absolute;
    right: calc(50% - 6px);
    top: ${rem(13)};
    transition: ${getCssVar('transitions.smooth')};
    width: ${rem(11)};
    z-index: 12;

    &::after {
        background-color: ${getCssVar('body.textColorAlt')};
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: ${rem(11)};
        height: 1px;
        width: ${rem(50)};
        z-index: 10;

        ${breakpoint('down', 'lg')} {
            width: ${rem(30)};
        }

        ${breakpoint('down', 'md')} {
            width: ${rem(20)};
        }
    }

    ${props => props.active && css`
        background-color: ${getCssVar('body.linkColor')};
        &::before {
            background-color: ${getCssVar('body.linkColor')};
            border-radius: 50%;
            content: '';
            display: block;
            filter: blur(3px);
            opacity: 0.6;
            position: absolute;
            top: -3px;
            left: -3px;
            height: ${rem(17)};
            width: ${rem(17)};
            z-index: 0;
        }
    `}
`

export const TimeLineItem = styled.div`
    padding-left: 60%;
    position: relative;

    &:nth-child(even) {
        padding-left: 0;
        padding-right: 60%;
        text-align: right;

        h3 { text-align: right; }

        ${Dot}:after {
            left: -${rem(50)};
        }
    }

    // ${breakpoint('down', 'md')} {
    //     padding: ${rem(20)} 10% ${rem(20)} 20%;
    //     &:nth-child(even) {
    //         padding: ${rem(20)} 10% ${rem(20)} 20%;
    //         text-align: left;

    //         h3 { text-align: left; }

    //         ${Dot}:after {
    //             left: 0;
    //         }
    //     }
    // }
`


export const Event = styled(H3)`
    padding: ${rem(10)} 0;
`
export const Where = styled(P)``
