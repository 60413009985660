import { colors } from '../colors'
import { button } from './button'

export const tab = {
  font: '"Roboto", "Helvetica", "Arial", sans-serif',
  primary: {
    bgColor: colors.lightGrey,
    borderColor: colors.lightGrey,
    textColor: colors.darkGrey,
  },
  hover: {
    bgColor: colors.midGrey,
    borderColor: colors.midGrey,
    textColor: colors.darkGrey,
  },
  selected: {
    bgColor: colors.white,
    borderColor: colors.white,
    textColor: colors.blue,
  },
  disabled: {
    bgColor: button.disabled.bgColor,
    borderColor: button.disabled.borderColor,
    textColor: button.disabled.textColor,
  },
  radius: '12px',
}
