import { hexToRGBa, lightenDarkenColor } from 'helpers/functions'
import { colors } from '../colors'
import { border } from '../border'
import { font } from '../fonts'

export type InputSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const input = {
  bgColor: colors.white,
  borderColor: border.borderColor,
  textColor: colors.slate,
  labelColor: colors.slate,
  tooltipColor: colors.grey,
  placeholderColor: colors.darkGrey,
  helperColor: colors.darkGrey,
  disabled: {
    bgColor: colors.lightGrey,
    borderColor: border.borderColor,
    textColor: lightenDarkenColor(colors.midGrey, -30),
  },
  focus: {
    outline: colors.yellow,
  },
  error: {
    bgColor: hexToRGBa(colors.red, 0.1),
    borderColor: colors.red,
    textColor: colors.red,
  },
  fontSize: {
    label: {
      xs: font.size.tiny,
      sm: font.size.xxs,
      md: font.size.xs,
      lg: font.size.sm,
      xl: font.size.md,
    },
    input: {
      xs: font.size.xxs,
      sm: font.size.xs,
      md: font.size.sm,
      lg: font.size.md,
      xl: font.size.lg,
    },
    helper: {
      xs: font.size.micro,
      sm: font.size.tiny,
      md: font.size.xxs,
      lg: font.size.xs,
      xl: font.size.sm,
    },
  },
}
