import { Link } from 'react-router-dom'
import { default as OTPComp } from 'components/auth/otp'
import PageHeader from 'components/layout/pageHeader'
import { StyledLogo } from '../home/styles'
import { H1 } from 'components/typography/H1'

import { StyledLayout, StyledContent, StyledHeading } from '../login/styles'



function OTP() {
  return (
    <StyledLayout>
      <PageHeader>
        <StyledHeading>
         <H1>
            <Link to="/">
              <StyledLogo />
            </Link>
          </H1>
        </StyledHeading>
      </PageHeader>
      <StyledContent>
        <OTPComp />
      </StyledContent>
    </StyledLayout>
  )
}

export default OTP
