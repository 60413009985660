import styled from 'styled-components'
import { breakpoint, rem } from 'helpers/functions'
import PageSection from 'components/layout/pageSection'
import Map from 'pages/components/map'

export const StyledHeaader = styled.div`
  margin-bottom: ${rem(50)};
`

export const PageSectionMap = styled(PageSection)`
  height: 900px;
  padding: 0 !important;
  position: relative;
  > div {
    height: 100%;
  }
`

export const StyledMap = styled(Map)`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 0;
`

export const StyledSubHeading = styled.div`
  margin: 0 auto;
  max-width: ${rem(1200)};
  text-align: center;

  ${breakpoint('down', 'lg')} {
    max-width: 80%;
  }
`

export const StyledPageSection = styled(PageSection)`
  text-align: justify;
  padding: ${rem(100)};

  ${breakpoint('down', 'lg')} {
    padding: ${rem(60)};
  }

  ${breakpoint('down', 'md')} {
    padding: ${rem(30)};
  }
`