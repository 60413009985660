import { Icon } from '.'
import { IconProps } from './types'

export const AttireIconSVG = () => (
  <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="m1130.4 920h-1060.7c-20.973-0.0625-40.801-9.5586-53.996-25.859-13.195-16.297-18.355-37.668-14.051-58.191 4.3047-20.527 17.617-38.023 36.246-47.648l503.32-259.28c4.7305-2.6094 10.316-3.1953 15.484-1.625 5.168 1.5742 9.4805 5.168 11.957 9.9727 2.4766 4.8008 2.9023 10.402 1.1836 15.523s-5.4336 9.332-10.305 11.668l-503.32 259.28c-7.9336 4.1016-13.605 11.551-15.449 20.293-1.8398 8.7383 0.34766 17.844 5.9531 24.797 5.6094 6.9531 14.043 11.02 22.977 11.07h1060.7c8.7266-0.042969 16.996-3.918 22.609-10.598 5.6133-6.6836 8.0078-15.496 6.5469-24.098-1.4609-8.6055-6.6289-16.133-14.137-20.586 0 0-538-319.22-621.02-368.3v0.003906c-17.957-9.918-32.895-24.508-43.234-42.227-10.336-17.719-15.688-37.902-15.484-58.414-0.40625-30.895 11.582-60.664 33.281-82.652 21.703-21.992 51.312-34.367 82.207-34.367 30.898 0 60.504 12.375 82.207 34.367 21.703 21.988 33.691 51.758 33.285 82.652 0 7.1445-3.8125 13.746-10 17.32-6.1875 3.5703-13.812 3.5703-20 0-6.1875-3.5742-10-10.176-10-17.32 0.32812-20.234-7.4805-39.754-21.672-54.18-14.191-14.426-33.582-22.551-53.82-22.551-20.234 0-39.625 8.125-53.816 22.551-14.195 14.426-22 33.945-21.672 54.18-0.26172 13.574 3.2578 26.957 10.16 38.652 6.9062 11.691 16.926 21.238 28.938 27.566 82.922 49.059 621.04 368.3 621.04 368.3h0.003906c17.637 10.449 29.781 28.137 33.199 48.352 3.4219 20.215-2.2305 40.91-15.453 56.582-13.219 15.672-32.664 24.73-53.168 24.766z"/>
  </svg>
)

export const AttireIcon = (props: IconProps) => (
  <Icon {...props}>
    <AttireIconSVG />
  </Icon>
)