import PageLayout from 'components/layout/pageLayout'
import PageSection from 'components/layout/pageSection'
import ImageBoard from 'pages/components/imageBoard'
import PageHeader from 'components/layout/pageHeader'
import { P } from 'components/typography/P'
import { Space } from 'components/layout/Space'
import {  StyledMap, PageSectionMap, StyledSubHeading, StyledPageSection } from './styles'

const items = [
  '/inglewood-barn.jpg',
  '/inglewood-chapel.jpg',
  '/inglewood-garden.jpg',
  '/inglewood-house.jpg',
  '/inglewood-lake.jpg',
  '/inglewood-chapel-2.jpg'
]

function Venue() {
  return (
    <PageLayout>
      <PageHeader heading="The Venue - Inglewood Estate" subHeading={(
        <StyledSubHeading>
        <Space align="h" size="xxl" />
          <P>Nestled in the scenic landscape of Eltham, Victoria, Inglewood Estate is a charming wedding venue located at 130 Eltham-Yarra Glen Road, Kangaroo Ground, VIC 3097. It's easy to reach from Melbourne CBD by car via the M3 Eastern Freeway, taking the Eltham exit and continuing on Eltham-Yarra Glen Road until you see the estate on your left. Ample parking is available with clear signs guiding you to the designated areas. For those using public transport, take the Hurstbridge Line train from Melbourne CBD to Eltham Station, followed by a short 10-minute taxi or rideshare trip to the venue. Ubers and taxis are also readily available; simply enter "Inglewood Estate, Kangaroo Ground" as your destination, and your driver will bring you directly to the venue. We look forward to celebrating with you at this beautiful location!</P>
        </StyledSubHeading>
      )}/>
      <StyledPageSection>
        <ImageBoard items={items} />
      </StyledPageSection>
      <PageSectionMap fullWidth>
        <StyledMap />
      </PageSectionMap>
    </PageLayout>
  )
}

export default Venue
