import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'
import { rem, hexToRGBa, breakpoint } from 'helpers/functions'
import { LoadingRing } from 'components/loaders/LoadingRing'
import { ColorSchemeProps, VariantProps } from 'types/styles'
import { BaseButtonProps } from './types'

export const ButtonStyles = `
  align-items: center;
  background-color: ${getCssVar('button.primary.bgColor')};
  border: ${getCssVar('border.common')};
  border-radius: ${getCssVar('border.borderRadius')};
  color: ${getCssVar('button.primary.textColor')};
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-family: ${getCssVar('font.tertiaryFont')};
  font-size: ${getCssVar('font.size.sm')};
  font-weight: ${getCssVar('font.weight.medium')};
  justify-content: center;
  line-height: 1.1;
  min-width: ${rem(80)};
  padding: ${rem(16)} ${rem(40)};
  transition: ${getCssVar('transitions.smooth')};

  > span {
    line-height: 1.1;
  }

  &:hover {
    background-color: ${getCssVar('button.primary.filledBgHover')};
  }

  ${breakpoint('down', 'md')} {
    padding: ${rem(12)} ${rem(20)};
  }
`

export const StyledButton = styled.span<BaseButtonProps>`
  ${ButtonStyles}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.iconPlacement === 'right' &&
    css`
      flex-direction: row-reverse;
    `}

  & > span {
    pointer-events: none;
    user-select: none;
  }

  & > .button-icon {
    display: inline-flex;
  }

  & > .button-label {
    display: inline-flex;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
  }

  ${(props) => mapButtonColorVariants(props.color || 'primary', props.variant || 'fill')}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const StyledLoadingRing = styled(LoadingRing)<Partial<BaseButtonProps>>`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: ${rem(36)};
`

export function mapButtonColorVariants(col: ColorSchemeProps, variant: VariantProps) {
  const white = getCssVar(`colors.white`)

  let txtCol = white
  let bgCol: string = col
  let borCol: string = col
  let txtHovCol = white
  let bgHovCol: string = col
  let borHovCol: string = col

  switch (variant) {
    case 'subtle':
      txtCol = getCssVar(`button.${col}.bgColor`)
      bgCol = hexToRGBa('#fff', 0.6)
      borCol = getCssVar('border.borderColor')
      txtHovCol = getCssVar(`button.${col}.subtleTxtHover`)
      bgHovCol = getCssVar(`button.${col}.subtleBgHover`)
      borHovCol = getCssVar(`button.${col}.subtleBgHover`)
      break
    case 'light':
      txtCol = getCssVar(`button.${col}.bgColor`)
      bgCol = getCssVar(`button.${col}.lightBgColor`)
      borCol = getCssVar(`button.${col}.lightBgColor`)
      txtHovCol = getCssVar(`button.${col}.bgColor`)
      bgHovCol = getCssVar(`button.${col}.lightBgHover`)
      borHovCol = getCssVar(`button.${col}.lightBgHover`)
      break
    case 'outline':
      txtCol = getCssVar(`button.${col}.bgColor`)
      bgCol = 'transparent'
      borCol = getCssVar(`button.${col}.bgColor`)
      txtHovCol = getCssVar(`button.${col}.bgColor`)
      bgHovCol = getCssVar(`button.${col}.outlineBgHover`)
      borHovCol = getCssVar(`button.${col}.borderColor`)
      break
    case 'social':
      txtCol = getCssVar(`colors.darkGrey`)
      bgCol = 'transparent'
      borCol = getCssVar(`colors.border`)
      txtHovCol = getCssVar(`button.${col}.bgColor`)
      bgHovCol = getCssVar(`button.${col}.outlineBgHover`)
      borHovCol = getCssVar(`colors.teal`)
      break
    case 'default':
    case 'fill':
    default:
      txtCol = getCssVar(`button.${col}.textColor`)
      bgCol = getCssVar(`button.${col}.bgColor`)
      borCol = getCssVar(`button.${col}.borderColor`)
      txtHovCol = getCssVar(`button.${col}.textColor`)
      bgHovCol = getCssVar(`button.${col}.filledBgHover`)
      borHovCol = getCssVar(`button.${col}.filledBgHover`)
      break
  }

  return css`
    background-color: ${bgCol};
    border-color: ${borCol};
    color: ${txtCol};

    &:hover {
      background-color: ${bgHovCol};
      border-color: ${borHovCol};
      color: ${txtHovCol};
    }
  `
}
