import { Icon } from '.'
import { IconProps } from './types'

export const ShowPasswordIconSVG = ({ fill }: { fill?: string }) => (
    <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="m100 600c276.14 466.66 723.86 466.66 1e3 0-276.14-466.66-723.86-466.66-1e3 0zm500 150c-39.781 0-77.938-15.805-106.07-43.934s-43.934-66.285-43.934-106.07 15.805-77.938 43.934-106.07 66.285-43.934 106.07-43.934 77.938 15.805 106.07 43.934 43.934 66.285 43.934 106.07-15.805 77.938-43.934 106.07-66.285 43.934-106.07 43.934z"/>
    </svg>
)

export const ShowPasswordIcon = (props: IconProps & { fill?: string }) => {
  const { fill } = props
  return (
    <Icon {...props}>
      <ShowPasswordIconSVG fill={fill} />
    </Icon>
  )
}