import styled, { keyframes } from 'styled-components'
import { ImageProps, BecAndSamPathProps } from './types'

export const StyledImage = styled.div<Partial<ImageProps>>`
  display: inline-flex;
  user-select: none;
  color: inherit;

  & > svg,
  & > i {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`

const draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`


export const StyledPath = styled.path<BecAndSamPathProps>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${draw} 5s linear forwards;
`
