import { Icon } from '.'
import { IconProps } from './types'

export const PageNextIconSVG = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="m13.333 8-1.88 1.88L17.56 16l-6.107 6.12 1.88 1.88 8-8-8-8Z" />
  </svg>
)

export const PageNextIcon = (props: IconProps) => (
  <Icon {...props}>
    <PageNextIconSVG />
  </Icon>
)