import styled, { keyframes, css } from 'styled-components'
import { breakpoint, rem } from 'helpers/functions'
import { getCssVar } from 'theme'
import { ChevronIcon } from 'components/icons/ChevronIcon'

export const StyledPageHeader = styled.div<{ bgImg: boolean, fullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 8;

  ${(props) =>
    props.bgImg &&
    css`
      padding-bottom: ${rem(300)};
      height: 100vh;
    `}

  ${props =>
    props.fullHeight &&
    css`
      justify-content: center;
      height: 95vh;
      max-height: ${rem(1500)};
    `
  }

  ${breakpoint('down', 'md')} {
    height: auto;
  }
`

const upAndDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`

export const StyledChevronIcon = styled(ChevronIcon)<Partial<{ scrolled: boolean }>>`
  align-self: center;
  animation: ${upAndDown} 2s ease-in-out infinite;
  cursor: pointer;
  margin-top: ${rem(50)};
  width: ${rem(50)};
  transition: ${getCssVar('transitions.smooth')};

  &:hover {
    color: ${getCssVar('body.linkColor')};
    transform: scale(2, 2);
  }
`

export const StyledBgImage = styled.div`
  background: url(${process.env.REACT_APP_PUBLIC_URL}/photo.jpg);
  background-size: 100% auto;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const StyledHeading = styled.div<{ fullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(200)} ${rem(40)} ${rem(40)};
  text-align: center;

  h1, h3 {
    margin: 0 auto;
    text-align: center;
    width: 80%;
  }

  ${props =>
    props.fullHeight &&
    css`
      padding: ${rem(100)} ${rem(40)} ${rem(80)};
    `
  }

  ${breakpoint('down', 'md')} {
    margin-top: ${rem(100)}; 
    padding: ${rem(80)} ${rem(40)} ${rem(40)};
  }
`