import PageLayout from 'components/layout/pageLayout'
import { rem } from 'helpers/functions'
import styled from 'styled-components'

export const StyledLayout = styled(PageLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledContent = styled.div`
  display: flex;
  padding: 0 !important;
  width: 100%;
`

export const StyledHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(120)} ${rem(40)} ${rem(10)};

  h1 {
    margin: 0 auto;
    max-width: ${rem(850)};
    width: 80%;
  }
`
