import styled from "styled-components"

export const StyledNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 12;
`

export const StyledBgImg = styled.div`
  background-image: url('/daisys.gif');
  background-size: cover;
  position: fixed;
  pointer-events: none;
  top: 0; right: 0; left: 0; bottom: 0;
  z-index: -1;
`