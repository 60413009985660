import styled, { keyframes } from 'styled-components'
import { ImageProps, BecAndSamPathProps } from './types'
import { Image } from '.'

const draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

export const StyledPath = styled.path<BecAndSamPathProps>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${draw} 5s linear forwards;
`

export const BecSamSvg = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 1166 372">
      <StyledPath
        sam={true}
        d="M886.87,207.36c-1.78,2.36-3.58,4.71-5.34,7.08c-8,10.72-16.95,20.52-27.72,28.52
          c-5.63,4.18-11.76,7.72-18.63,9.14c-11.12,2.3-20.92-2.46-26.12-13.12c-2.66-5.46-3.84-11.27-3.59-17.37
          c0.04-1.06-0.14-2.12-0.27-3.9c-2.29,3.88-4.13,7.23-6.18,10.44c-2.79,4.37-5.92,8.42-10.77,10.8c-3.61,1.76-7.39,1.75-10.86,0.32
          c-6.87-2.84-9.92-8.48-11.5-15.67c-1.47-6.65,0.11-12.9,0.69-19.31c0.15-1.7,1.05-3.32,1.39-5.02c0.25-1.27-0.17-1.89-1.68-1.18
          c-9.21,4.33-18.5,8.5-27.68,12.9c-8.89,4.26-17.8,8.5-26.47,13.17c-10.21,5.49-20.3,11.24-30.21,17.26
          c-6.23,3.78-12,8.31-18.05,12.39c-1.74,1.18-2.17,2.64-2.34,4.61c-0.57,6.53-0.8,13.17-2.23,19.52
          c-1.43,6.32-3.99,12.41-6.35,18.49c-2.49,6.4-6.29,12.05-10.99,17.08c-4.63,4.96-10.35,6.59-16.78,4.82
          c-1.82-0.5-3.7-2.14-4.74-3.77c-2.19-3.44-3.16-7.26-1.45-11.37c1.48-3.58,2.36-7.47,4.24-10.81c2.95-5.25,6.03-10.58,9.94-15.12
          c5.73-6.67,12.2-12.71,18.43-18.93c1.48-1.48,2.05-2.94,1.8-5.07c-0.61-5.37-0.73-10.81-1.42-16.17
          c-0.72-5.5-1.86-10.95-2.88-16.41c-0.78-4.15-1.6-8.3-2.52-12.43c-0.87-3.88-1.9-7.72-2.86-11.57c-0.68-2.73-1.43-5.45-2.03-8.19
          c-0.81-3.72-1.5-7.47-2.28-11.2c-0.92-4.38-1.87-8.75-2.82-13.13c-0.07-0.33-0.22-0.64-0.26-0.97c-1.2-10.4-3.33-20.8-1.98-31.3
          c1.33-10.3,4.72-19.92,10.95-28.47c6.91-9.48,15.45-17.03,25.33-23.27c7.76-4.91,16.04-8.71,24.63-11.72
          c5.89-2.06,12.23-2.84,18.3-4.45c6.14-1.63,12.37-1.7,18.6-1.39c7.74,0.39,15.15,2.16,21.38,7.14c5.69,4.55,8.16,10.69,7.49,17.82
          c-0.35,3.75-1.37,7.55-2.77,11.05c-2.51,6.29-5,12.68-8.46,18.46c-5.19,8.67-10.82,17.15-16.98,25.16
          c-6.73,8.75-14.24,16.91-21.51,25.24c-1.35,1.54-3.1,2.78-4.81,3.93c-1.86,1.25-4.07,0.89-5.43-0.7c-1.46-1.7-1.4-3.65-0.06-5.25
          c1.81-2.16,3.87-4.11,5.91-6.06c9.07-8.71,17.7-17.81,25.45-27.75c6.55-8.4,12.48-17.22,17.26-26.7
          c2.44-4.84,4.11-10.14,5.55-15.39c1.74-6.37-2.33-13.48-8.82-15.94c-5.96-2.26-12.05-2.36-18.17-1.87
          c-5.36,0.42-10.79,1.04-15.97,2.4c-6.06,1.58-12,3.79-17.77,6.23c-5.32,2.26-10.51,4.92-15.48,7.89
          c-9.87,5.89-18.28,13.37-24.83,23.01c-9.26,13.63-9.86,28.54-7.58,44.05c0.17,1.15,0.35,2.31,0.54,3.46
          c1.11,6.7,2.21,13.41,3.33,20.11c0.74,4.42,1.42,8.85,2.29,13.24c0.82,4.14,1.89,8.23,2.8,12.36c0.76,3.48,1.59,6.96,2.11,10.48
          c0.94,6.4,1.71,12.83,2.49,19.26c0.62,5.09,1.16,10.2,1.79,15.79c3.2-2.15,6.07-4.2,9.05-6.06c9.35-5.85,18.65-11.79,28.15-17.38
          c8.06-4.74,16.32-9.17,24.61-13.51c6.53-3.41,13.25-6.47,19.88-9.68c6.85-3.32,13.7-6.64,20.55-9.96c1.49-0.72,2.94-1.63,4.51-2.07
          c2.48-0.7,3.56-2.67,4.59-4.63c4.68-8.94,10.8-16.58,19.62-21.7c4.87-2.83,10.21-4.69,15.94-4.01c7.05,0.84,14.63,5.83,17.18,13.7
          c0.99,3.04,1.97,5.96,1.73,9.19c-0.1,1.4-0.25,2.77-2,2.97c-1.88,0.22-2.45-1.05-2.68-2.59c-0.34-2.3-0.76-4.61-0.95-6.92
          c-0.34-4.17-1.92-7.47-5.79-9.5c-2.95-1.55-6.05-1.39-8.96-0.5c-7.32,2.23-12.45,7.42-16.83,13.43
          c-5.57,7.65-9.54,16.14-12.4,25.09c-2.26,7.08-3.24,14.45-2.21,21.9c0.55,3.99,3.24,8.69,6.8,8.34c1.91-0.19,4.03-0.54,5.58-1.55
          c4.6-2.97,7.34-7.62,9.89-12.3c4.02-7.37,6.65-15.3,9.44-23.18c1.01-2.84,2.7-5.48,4.3-8.07c1.06-1.72,3.05-2.57,4.78-1.58
          c1.5,0.86,2.63,2.55,3.57,4.08c0.45,0.72,0.28,1.98,0.08,2.93c-0.87,4.13-2.06,8.21-2.75,12.37c-1.32,8.02-3.54,16.02-1.95,24.25
          c1.04,5.42,2.38,10.87,7.72,13.88c1.59,0.89,3.54,1.36,5.38,1.57c7.82,0.89,14.37-2.37,20.67-6.5
          c12.58-8.26,22.29-19.35,30.93-31.45c3.46-4.84,6.61-9.93,9.67-15.04c1.29-2.16,2.19-4.62,2.9-7.05c1.81-6.15,3.97-12.12,7.51-17.5
          c1.37-2.08,3.15-3.67,5.91-3.54c1.98,0.1,3.63,0.85,3.51,3.03c-0.21,3.7-0.59,7.4-1.21,11.05c-0.45,2.6-1.46,5.09-2.14,7.65
          c-1.39,5.19-2.7,10.41-4.08,15.6c-1.55,5.84-3.27,11.64-4.68,17.52c-0.96,4.02-1.52,8.14-2.22,12.23
          c-0.38,2.22-0.73,4.44-0.95,6.68c-0.05,0.52,0.43,1.09,0.67,1.64c0.48-0.25,1.16-0.37,1.4-0.76c1.81-2.97,3.68-5.91,5.26-9.01
          c2.86-5.6,5.6-11.26,8.26-16.95c2.11-4.5,4.02-9.09,6.04-13.63c2.09-4.69,4.15-9.39,6.32-14.04c2.63-5.62,5.09-11.34,8.11-16.75
          c3.21-5.77,6.67-11.47,10.62-16.76c2.85-3.82,6.3-2.84,7.97,1.67c2.08,5.65,2.96,11.5,3.55,17.48c0.62,6.27,1.8,12.48,2.86,18.69
          c0.67,3.92,1.32,7.87,2.42,11.68c1.05,3.63,2.36,7.26,4.12,10.59c1.94,3.68,6.6,4.2,10.12,1.73c5.61-3.95,9.53-9.43,13.64-14.76
          c4.05-5.25,7.53-11.01,12.97-15.06c2.53-1.88,5.82-2.17,8.04-0.01c2.95,2.87,5.71,6.03,8.06,9.41c4.76,6.84,9.62,13.59,16.29,18.72
          c3.73,2.87,7.8,5.12,12.36,6.76c6.17,2.22,12.41,2.2,18.43,1.02c4.81-0.94,9.51-3.36,13.81-5.89c10.9-6.39,18.83-15.9,26.03-26.08
          c0.67-0.95,1.3-2.02,2.2-2.68c0.59-0.43,1.65-0.23,2.49-0.3c0.03,0.71,0.36,1.65,0.03,2.1c-3.98,5.4-7.8,10.94-12.17,16.01
          c-5.52,6.41-12.2,11.6-19.77,15.37c-8.11,4.04-16.57,6.26-25.92,4.82c-10.57-1.63-19.04-6.71-25.98-14.4
          c-4.88-5.4-9.08-11.42-13.59-17.17c-0.41-0.52-0.82-1.05-1.26-1.54c-3.81-4.23-5.69-4.26-9.27,0.31
          c-4.1,5.23-7.82,10.76-11.99,15.92c-4.17,5.16-8.48,10.26-14.63,13.33c-5.99,2.99-11.35,1.33-14.61-3.58
          c-2.63-3.96-4.68-8.19-5.64-12.9c-1.53-7.46-3.44-14.86-4.64-22.38c-1.15-7.22-1.6-14.54-2.4-21.82c-0.08-0.75-0.52-1.47-0.79-2.2
          c-0.71,0.46-1.71,0.76-2.08,1.41c-3.68,6.38-7.43,12.73-10.8,19.28c-3.52,6.84-6.71,13.86-9.91,20.87
          c-2.75,6.02-5.12,12.22-8.03,18.15c-4.49,9.13-9.22,18.15-14.07,27.09c-0.97,1.79-2.79,3.32-4.54,4.46
          c-3.23,2.1-7.28,0.38-8.62-3.28c-2.66-7.23-1.85-14.59-0.87-21.95c0.2-1.48,0.5-2.95,0.75-4.42c0.52-3.02,1.07-6.04,1.53-9.08
          c0.23-1.53,0.29-3.09,0.42-4.63C887.4,207.56,887.13,207.46,886.87,207.36z M627.66,313.34c1.66-0.66,3.51-1.05,4.94-2.04
          c9.77-6.77,14.33-16.96,17.36-27.87c1.47-5.3,1.8-10.92,2.54-16.41c0.13-0.98-0.27-2.03-0.42-3.05c-0.83,0.46-1.66,0.93-2.48,1.4
          l-0.19,0.16c-10.1,9.83-19.32,20.36-24.67,33.61c-1.36,3.36-2.05,7.15-1.01,10.99C624.3,312.23,625.58,312.92,627.66,313.34z"
      />
      <StyledPath
        and={true}
        d="M313.74,201.81c-1.62,1.77-3.12,3.68-4.89,5.28c-8.02,7.23-15.79,14.78-24.29,21.39
          c-5.7,4.43-12.24,8.01-18.84,10.99c-6.95,3.14-14.48,5.15-22.29,3.96c-8.57-1.3-15.52-5.46-19.68-13.25
          c-4.64-8.69-6.45-17.91-3.86-27.69c0.27-1.03,0.27-2.15,0.32-3.23c0.12-2.18-0.52-2.74-2.64-2.03c-4.47,1.49-8.87,3.18-13.35,4.61
          c-2.91,0.93-5.92,1.56-8.9,2.28c-0.8,0.19-1.65,0.2-2.48,0.33c-5.18,0.81-5.76,1.46-5.79,6.59c-0.04,6.95-1.48,13.63-3.26,20.33
          c-2.21,8.33-5.57,16.13-9.68,23.61c-5.09,9.28-11.37,17.74-18.53,25.56c-9,9.82-19.19,18.21-30.73,24.79
          c-6.3,3.59-12.97,6.76-19.82,9.12c-10.11,3.47-20.69,5.19-31.41,3.91c-9.41-1.12-18.45-3.72-25.52-10.59
          c-4.83-4.69-8.17-10.3-8.44-17.23c-0.16-4.22-0.45-8.56,0.3-12.67c0.8-4.34,2.39-8.64,4.28-12.64
          c6.38-13.48,16.28-24.08,28.56-32.26c6.72-4.48,14.1-8.01,21.32-11.67c3.74-1.9,7.78-3.23,11.74-4.68c2.17-0.79,4.42-1.43,6.68-1.9
          c6.89-1.45,13.81-2.79,20.72-4.16c4.55-0.9,9.11-1.79,13.66-2.68c0.49-0.1,0.97-0.24,1.46-0.31c5.67-0.78,11.36-1.5,17.02-2.33
          c2.96-0.43,5.93-0.92,8.82-1.65c0.71-0.18,1.68-1.46,1.63-2.18c-1.02-17.35-8.46-31.33-22.43-41.7
          c-5.17-3.84-10.88-6.66-17.38-7.53c-3.36-0.45-6.75-1.25-10.09-1.09c-5.48,0.26-10.7,2.05-15.78,4.12
          c-0.61,0.25-1.26,0.73-1.85,0.67c-3.02-0.32-4.09,1.8-4.94,3.97c-1.44,3.7-2.51,7.55-4.03,11.21c-1.68,4.04-3.58,8.01-5.66,11.85
          c-0.49,0.9-2.27,1.81-3.18,1.59c-0.95-0.23-2.25-1.84-2.23-2.82c0.1-5.97,0.51-11.93,0.88-17.89c0.1-1.57,0.3-3.15,0.6-4.69
          c1-5.2,2.05-10.4,3.12-15.59c0.69-3.33,1.41-6.65,2.2-9.96c1.01-4.26,2.08-8.51,3.16-12.75c0.65-2.57,1.3-5.13,2.05-7.68
          c2.34-8.02,4.58-16.08,7.13-24.03c1.61-5.03,3.59-9.96,5.64-14.83c0.57-1.36,1.83-2.56,3.03-3.5c2.13-1.66,4.6-0.76,4.66,1.93
          c0.07,3.33-0.31,6.77-1.11,10.01c-1.05,4.24-2.69,8.33-4.09,12.48c-1.61,4.78-3.25,9.54-4.83,14.33c-1.4,4.24-2.76,8.49-4.11,12.75
          c-1.61,5.05-3.2,10.1-4.79,15.16c-0.72,2.29-1.53,4.56-2.08,6.89c-1.46,6.12-2.83,12.26-4.18,18.41c-0.68,3.11-1.24,6.25-1.86,9.38
          c0.29,0.08,0.59,0.16,0.88,0.24c0.65-1.41,1.41-2.79,1.93-4.25c2.02-5.69,3.91-11.43,5.96-17.11c1.43-3.97,3.05-7.87,4.58-11.8
          c1.35-3.47,2.67-6.95,4.04-10.41c2.02-5.08,3.99-10.18,6.15-15.19c2.04-4.71,4.2-9.37,6.5-13.95
          c6.44-12.83,12.65-25.85,23.02-36.16c2-1.98,4.57-3.58,7.16-4.72c3.81-1.68,8.05,0.05,9.73,3.84c1.01,2.29,1.71,4.84,1.94,7.33
          c0.62,6.89-0.8,13.45-3.19,19.97c-5.69,15.58-14.45,29.26-25.11,41.79c-3.61,4.24-7.67,8.08-11.52,12.12
          c-0.61,0.64-1.18,1.31-2.18,2.41c1.5,0.19,2.43,0.33,3.36,0.43c5.7,0.62,11.55,0.65,17.09,1.96c13.68,3.23,24.63,10.65,32.46,22.58
          c3.55,5.41,6.07,11.22,8.07,17.27c1.13,3.43,1.61,7.1,2.14,10.7c0.41,2.71,0.54,3.12,3.27,2.56c3.32-0.69,6.59-1.63,9.83-2.64
          c4.42-1.38,8.86-2.73,13.14-4.48c3.33-1.36,6.84-2.84,9.5-5.15c2.19-1.9,3.28-5.07,4.81-7.7c3.16-5.4,6.55-10.66,11.54-14.57
          c3.62-2.84,7.41-5.32,12.45-4.7c3.27,0.4,6.57,2.95,6.71,6.27c0.11,2.69-0.3,5.45-0.82,8.11c-0.57,2.91-1.37,5.8-2.38,8.59
          c-3.68,10.18-8.92,19.3-18.54,25.06c-1.33,0.8-3.15,0.9-4.77,1.06c-1.4,0.14-2.21,0.61-2.26,2.06c-0.25,6.55-0.07,13.01,4.05,18.59
          c3.16,4.29,7.36,6.81,12.75,7.32c9.68,0.92,18.28-2.18,26.53-6.86c13.67-7.76,25.15-18.17,35.8-29.56
          c2.55-2.73,5.16-5.48,7.21-8.58c1.89-2.85,3.02-6.21,4.59-9.29c4.13-8.11,8.75-15.9,16.22-21.44c1.68-1.25,3.76-2.22,5.81-2.68
          c4.84-1.09,8.56,3.46,8.96,7.61c0.65,6.81-0.12,13.43-3.13,19.68c-0.79,1.64-1.33,3.43-2.35,4.9c-0.55,0.8-1.86,1.07-2.83,1.58
          c-0.42-0.92-1.36-1.97-1.16-2.72c0.62-2.37,1.72-4.61,2.53-6.93c1.47-4.22,3-8.42,4.21-12.71c0.29-1.03-0.26-3.13-0.96-3.42
          c-0.99-0.4-2.76,0.06-3.72,0.78c-4.62,3.47-7.63,8.34-10.22,13.35c-2.62,5.06-5,10.3-6.87,15.68c-3.01,8.69-4.12,17.78-1.94,26.79
          c1.79,7.4,8.06,13.57,16.56,13.83c2.08,0.06,4.17,0.03,6.25-0.02c5.53-0.12,10.52-2.28,15.34-4.59
          c15.07-7.24,27.04-18.57,38.67-30.24c3.92-3.93,7.27-8.43,10.88-12.68c0.32-0.38,0.54-1,0.93-1.14c1.06-0.37,2.19-0.54,3.29-0.79
          c-0.17,1.05,0.04,2.45-0.56,3.11c-6.06,6.63-12.02,13.37-18.47,19.6c-5.66,5.47-11.66,10.68-17.98,15.36
          c-5.23,3.87-11,7.1-16.81,10.05c-4.17,2.12-8.69,3.84-13.26,4.79c-5.33,1.11-10.75,1.17-16.21-0.7c-5.86-2.01-10.56-5.21-13.9-10.3
          c-2.47-3.75-4.11-7.82-4.87-12.38c-0.9-5.42-1.32-10.71,0.01-16.08c0.09-0.37-0.06-0.8-0.1-1.2
          C314.22,202.1,313.98,201.95,313.74,201.81z M77.32,311c7.87,0.14,14.85-1.2,21.83-3.37c7.83-2.44,15.2-5.8,22.01-10.17
          c12.36-7.93,23.28-17.56,32.46-29.16c7.59-9.58,13.8-19.9,17.86-31.39c2.85-8.07,4.53-16.47,4.68-25.1
          c0.07-3.82-0.07-4.17-3.82-3.85c-4.29,0.36-8.55,1.08-12.82,1.65c-1.4,0.19-2.8,0.39-4.2,0.62c-5.39,0.91-10.79,1.84-16.18,2.76
          c-4.57,0.78-9.2,1.31-13.71,2.38c-5.54,1.32-11.06,2.87-16.45,4.73c-6.78,2.34-13.61,4.7-20.07,7.78
          c-10.72,5.11-20.1,12.22-28.2,20.97c-6.59,7.11-11.96,15.06-14.36,24.43c-1.81,7.06-2.08,14.41,1.04,21.52
          c3.04,6.94,8.38,11.18,15.09,13.99C67.41,310.87,72.63,311.19,77.32,311z M108.29,148.27c2.51-1.8,4.55-3.04,6.35-4.59
          c10.24-8.81,18.14-19.59,25.27-30.91c3.7-5.87,6.89-12.08,10.11-18.24c1.56-2.99,2.9-6.15,3.9-9.37c1.24-4.02,2.1-8.17,2.94-12.31
          c0.31-1.51,0.29-3.18,0.02-4.7c-0.63-3.55-2.89-4.3-5.66-2.02c-5.27,4.35-8.95,10.01-12.52,15.69c-3.57,5.68-6.9,11.52-10.05,17.44
          c-2.84,5.32-5.38,10.8-7.89,16.29c-2.09,4.58-3.97,9.26-5.89,13.92c-2.14,5.19-4.24,10.4-6.28,15.63
          C108.29,145.87,108.42,146.78,108.29,148.27z M254.68,172.91c-0.6-1.9-1.03-3.25-1.45-4.6c-1.47,0.57-3.07,0.95-4.38,1.78
          c-1.23,0.78-2.26,1.95-3.2,3.1c-7.76,9.48-12.27,20.54-15.42,32.23c-0.54,2.01,0.34,2.79,2.4,2.36c3.82-0.79,6.68-3.27,8.78-6.17
          c3.28-4.53,6.36-9.32,8.7-14.38C252.23,182.64,253.25,177.54,254.68,172.91z"
      />
      <StyledPath
        bec={true}
        d="M479.4,250.06c-0.37,0-0.74-0.01-1.11-0.03c-4.11-0.23-7.37-2.22-9.18-5.61c-0.4-0.74-0.66-1.59-0.73-2.39
          c-0.34-3.64,0.2-7.23,1.66-10.96c3.18-8.16,7.71-15.21,11.71-21.04c4.15-6.03,8.55-12.19,13.46-18.83
          c3.38-4.57,6.9-9.14,10.31-13.56c1.27-1.65,2.54-3.29,3.8-4.94c0.48-0.63,0.68-1.2,0.68-1.98l0-1.6c0.01-4.81,0.02-9.78,0.3-14.69
          c0.12-2.24,0.58-4.42,1.03-6.53c0.16-0.76,0.32-1.51,0.46-2.27c0.25-1.32,0.57-2.63,0.87-3.89l0.13-0.55
          c1.85-7.65,4.53-13.46,8.45-18.3c2.57-3.17,5.44-4.94,8.76-5.41c0.44-0.06,0.89-0.09,1.35-0.09c3.42,0,7.68,1.87,8.95,5.98
          c0.37,1.19,0.6,2.39,0.83,3.54c0.1,0.5,0.2,1,0.31,1.49l0.44,1.99l-0.72,0.14c-0.14,2.4-0.82,4.62-1.47,6.79l-0.12,0.39
          c-1.99,6.56-5.19,12.36-7.76,16.69c-2.52,4.24-5.34,8.36-8.07,12.34l-1.06,1.55c-1.13,1.65-2.33,3.26-3.5,4.81
          c-0.88,1.17-1.75,2.34-2.6,3.53c-0.08,0.12-0.16,0.38-0.15,0.48c0.28,2.88,0.62,5.82,0.95,8.66l0.4,3.53
          c0.04,0.35,0.07,0.71,0.09,1.06c0.03,0.44,0.07,0.89,0.13,1.32c0.38,2.38,0.77,4.76,1.17,7.13l0.38,2.28
          c0.11,0.67,0.22,1.34,0.33,2c0.25,1.58,0.51,3.21,0.82,4.79c0.28,1.42,0.62,2.88,0.95,4.29c0.16,0.68,0.32,1.37,0.48,2.05
          l0.37,1.64c0.15,0.68,0.3,1.36,0.46,2.04c0.75-0.77,1.5-1.54,2.25-2.31c3.45-3.54,7.02-7.2,10.37-10.93
          c2.82-3.15,5.37-6.5,8.32-10.57c2.61-3.6,4.25-6.5,5.32-9.42c0.09-0.25,0.17-0.52,0.25-0.78c0.22-0.75,0.47-1.61,1.02-2.4
          c0.78-1.13,2.31-1.65,3.4-1.65c0.08,0,0.16,0,0.23,0.01c0.98,0.06,1.87,0.54,2.46,1.32c0.6,0.79,0.82,1.82,0.61,2.82l-0.08,0.39
          c-0.28,1.34-0.6,2.86-1.43,4.19c-2.04,3.29-4.18,6.45-6.35,9.38c-5.98,8.06-13.1,15.97-22.4,24.88c-0.34,0.33-0.68,0.67-1.01,1.01
          c-1.1,1.12-1.18,1.56-0.58,3.17c0.38,1.01,0.68,2.02,0.97,3.01c0.19,0.63,0.37,1.27,0.58,1.9c0.39,1.17,0.79,2.33,1.19,3.49
          c0.35,1.02,0.71,2.04,1.05,3.06c0.45,1.34,0.85,2.55,1.16,3.82c0.23,0.92,0.13,1.96-0.26,2.79c-0.6,1.28-1.8,2.07-3.12,2.07
          c-1.22,0-2.36-0.67-3.05-1.8c-0.68-1.1-1.26-2.37-1.77-3.88c-0.33-0.99-0.57-1.98-0.8-2.93c-0.12-0.5-0.24-0.99-0.37-1.48
          c-0.32-1.2-0.66-2.39-0.99-3.58c0,0-0.92-3.28-1.01-3.6c-0.48,0.38-0.96,0.77-1.44,1.16c-2.44,1.97-4.97,4.01-7.64,5.82
          c-4.23,2.87-8.6,5.51-12.98,7.85c-3.11,1.66-6.53,3.07-10.15,4.19C484.27,249.66,481.75,250.06,479.4,250.06z M510.47,184.26
          c-2.99,3.85-5.98,7.71-8.96,11.57c-2.44,3.15-4.87,6.3-7.31,9.45c-3.9,5.03-8.41,11.08-12.24,17.73c-2.4,4.16-5.02,9.16-6.32,14.64
          c-0.45,1.92-0.36,4.34,1.25,5.38c0.72,0.46,1.6,0.68,2.78,0.68c0,0,0,0,0,0c0.51,0,1.07-0.04,1.82-0.12c3.1-0.34,6.13-1.25,9-2.7
          l1.25-0.63c3.22-1.62,6.55-3.3,9.64-5.17c5.72-3.47,10.9-7.63,15.21-11.25c0.84-0.71,0.84-0.73,0.56-1.72
          c-0.23-0.81-0.39-1.61-0.54-2.39l-0.18-0.87l-0.24-1.1c-0.31-1.45-0.62-2.91-0.92-4.36l-0.39-1.85c-0.38-1.79-0.77-3.65-1.09-5.5
          c-0.36-2.09-0.65-4.21-0.94-6.26l-0.2-1.41c-0.27-1.96-0.54-3.91-0.8-5.87c-0.27-2.02-0.55-4.04-0.83-6.05
          c-0.11-0.8-0.23-1.6-0.35-2.42C510.59,184.1,510.53,184.18,510.47,184.26z M531.66,125.47c-0.55,0-1.24,0.16-2,0.46
          c-2.06,0.82-3.55,2.74-4.74,4.51c-2.57,3.83-4.49,8.33-5.86,13.76c-0.94,3.73-1.66,7.5-2.13,11.18c-0.31,2.43-0.51,5-0.61,7.9
          c0.74-1.04,1.47-2.1,2.16-3.16c2.76-4.25,4.96-7.81,6.93-11.18c1.49-2.55,2.73-5.2,3.75-7.45c1.17-2.58,2.1-4.92,2.84-7.15
          c0.78-2.35,1.5-4.51,1.29-6.69C533.08,125.47,532.19,125.47,531.66,125.47z"
      />
    </svg>
  )
}

export const BecAndSam = (props: ImageProps) => {
  return (
    <Image {...props}>
      <BecSamSvg />
    </Image>
  )
}