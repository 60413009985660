import styled from 'styled-components'
import { rem } from 'helpers/functions'
import { Carousel } from 'components/display/carousel'
import { Button } from 'components/inputs/Button'
import { breakpoint } from 'helpers/functions'
import { PageSectionLight } from '../../home/styles'

export const StyledSection = styled(PageSectionLight)`
  padding-top: ${rem(40)};
  position: relative;
  z-index: 2;

  ${breakpoint('down', 'md')} {
    padding-top: ${rem(20)};
  }
`

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const StyledUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${rem(500)};
  width: 100%;
  
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  h2 {
    display: flex;
    justify-content: center;
  }

  p {
    text-align: center;
  }
`

export const FormCarousel = styled(Carousel)`
  display: flex;
  width: 100%;
`

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledButton = styled(Button)`
  margin: 0 ${rem(20)};

  ${breakpoint('down', 'md')} {
    margin: 0;
  }
`