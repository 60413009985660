import { IconProps } from './types'
import { StyledIcon } from './styles'

type IconBaseProps = IconProps & { className?: string; children: React.ReactNode; onClick?: any }

export const Icon = (props: IconBaseProps) => {
  const { className, children, size = 'md', onClick } = props
  return (
    <StyledIcon className={className} aria-hidden="true" size={size} onClick={onClick}>
      {children}
    </StyledIcon>
  )
}
