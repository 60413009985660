import { Modal } from 'components/display/modal'
import { StyledLayout, StyledImage } from './styles'
import { useState } from 'react'
import Carousel from 'components/display/reactcarousel'

function ImageBoard({ items }: { items: string[] }) {
    const [open, setOpen] = useState<boolean>(false)
    const [img, setImg] = useState<string>('')
    return (
        <>
            <StyledLayout>
                {items.map((i, index) => (
                    <StyledImage onClick={() => { setOpen(true), setImg(i) }}><img src={i} /></StyledImage>
                ))}
            </StyledLayout>

            <Modal open={open} onClose={() => setOpen(false)} hideTrigger isFullScreen>
         
                        <img src={img}  />
     
            </Modal>
        </>
    )
}

export default ImageBoard
