import { lightenDarkenColor } from 'helpers/functions'
import { colors } from '../colors'
import { font } from '../fonts'

export const link = {
  fontSize: font.size.sm,
  fontWeight: font.weight.medium,
  textColor: colors.teal,
  hoverColor: lightenDarkenColor(colors.teal, -20),
}
