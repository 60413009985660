import { colors } from '../colors'
import { hexToRGBa } from 'helpers/functions'

export const switchComponent = {
  bgColor: colors.midGrey,
  selectedBgColor: colors.green,
  thumbBgColor: colors.white,
  borderColor: colors.border,
  focusedBorderColor: hexToRGBa(colors.darkGrey, 0.5),
  textColor: colors.darkGrey,
  disabledColor: hexToRGBa(colors.darkGrey, 0.4),
}
