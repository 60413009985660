import { rem } from 'helpers/functions'

export const font = {
  headingFont: 'Gilda Display, Mulish, Cormorant Infant, serif',
  defaultFont: 'Gilda Display, Mulish, Cormorant Infant, serif', // 'Helivetica, Arial, sans-serif',
  tertiaryFont: 'Helivetica, Arial, sans-serif', //`"Sedan", serif`,
  size: {
    base: rem(21), // Assumes the browser default, typically `16px`
    xxl: rem(50),
    xl: rem(42),
    lg: rem(32),
    md: rem(21),
    sm: rem(16),
    xs: rem(13),
    xxs: rem(12),
    tiny: rem(11),
    micro: rem(10),
  },
  weight: {
    thin: '300',
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
    extraBold: '800',
    black: '900',
  },
  lineHeight: {
    regular: '1.125em',
    medium: '1.25em',
    large: '1.5em',
    xlarge: '1.8em',
  },
}
