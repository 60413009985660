import React, { useEffect, useState } from 'react'
import {
  StyledComponentWrapper,
  StyledInputWrapper,
  StyledInputField,
  StyledErrorMessage,
  StyledIcon,
} from './styles'
import { InputFieldProps } from './types'

// eslint-disable-next-line react/display-name
export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      value,
      defaultValue,
      onChange,
      type = 'text',
      placeholder,
      sizeType,
      onEnterKey,
      errorMessage,
      error,
      isFullWidth,
      icon,
      iconOnClick,
      ...rest
    },
    ref
  ) => {
    const [displayedValue, setDisplayedValue] = useState(
      defaultValue || (typeof value !== 'string' ? value?.target?.value : value)
    )

    useEffect(() => {
      setDisplayedValue(value as string)
      return () => setDisplayedValue('')
    }, [value])

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && typeof onEnterKey === 'function') {
        onEnterKey(e)
      }

      if (e.key === 'Escape') {
        setDisplayedValue('')
      }
    }
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e)
      }
      setDisplayedValue(e.target.value)
    }

    return (
      <>
        <StyledComponentWrapper isFullWidth={isFullWidth}>
          <StyledInputWrapper
            sizeType={sizeType}
            error={error}
            isFullWidth={isFullWidth}
            {...rest}
          >
              <StyledInputField
                type={type}
                placeholder={placeholder}
                value={displayedValue}
                onChange={handleOnChange}
                onKeyUp={handleKeyUp}
                ref={ref}
                {...rest}
              />
             {icon && <StyledIcon onClick={iconOnClick}>{icon}</StyledIcon>}
          </StyledInputWrapper>
        </StyledComponentWrapper>
        {error && <StyledErrorMessage error={error}>{errorMessage}</StyledErrorMessage>}
      </>
    )
  }
)
