import { BackgroundDots } from 'components/display/background'
import { StyledSection, SectionContent } from './styles'

function PageSection({ className, fullHeight, fullWidth, children, showDots }: { className?: string, fullHeight?: boolean, fullWidth?: boolean, children: React.ReactNode, showDots?: boolean }) {
  return (
    <StyledSection className={className} fullHeight={fullHeight}>
      <SectionContent fullWidth={fullWidth}>{children}</SectionContent>
      {showDots && <BackgroundDots inSection={true} />}
    </StyledSection>
  )
}

export default PageSection
