import { NavLink } from 'react-router-dom'
import { StyledLogo, StyledNav, StyledNavLink, StyledLeft, StyledRight, StyledCenter } from './styles'
import { NavProps } from './types'
import { isloggedIn } from 'helpers/auth'
import { useBreakpoint } from 'hooks/useBreakpoint'

const Navigation = (props: NavProps) => {
  const { scrolled } = props
  const loggedIn = isloggedIn()
  const isMobile = useBreakpoint('down', 'md')

  return (
    <StyledNav scrolled={scrolled}>
      {!isMobile && (
        <StyledLeft>
          <NavLink to="/">
            <StyledLogo scrolled={scrolled} />
          </NavLink>
        </StyledLeft>
      )}
      {loggedIn && (
        <StyledCenter>
          <StyledNavLink to="/" variant="subtle">
            Home
          </StyledNavLink>
          <StyledNavLink to="/venue" variant="subtle">
            Venue
          </StyledNavLink>
          <StyledNavLink to="/schedule" variant="subtle">
            Schedule
          </StyledNavLink>
          <StyledNavLink to="/rsvp" variant="subtle">
            RSVP
          </StyledNavLink>
          {isMobile && (
            <StyledNavLink to="/logout" variant="subtle">
              Logout
            </StyledNavLink>
          )}
        </StyledCenter>
      )}
      {!isMobile && (
        <StyledRight>
          {loggedIn && (
            <StyledNavLink to="/logout" variant="subtle">
              Logout
            </StyledNavLink>
          )}
        </StyledRight>
      )}
    </StyledNav>
  )
}

export default Navigation
