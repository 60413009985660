import { Icon } from '.'
import { IconProps } from './types'

export const TickIconSVG = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path d="M23.4467 11.1154C22.939 10.6077 22.1159 10.6077 21.6082 11.1154L13.3349 19.3887L9.65791 15.7116C9.15023 15.2039 8.32712 15.2039 7.81944 15.7116C7.31175 16.2193 7.31175 17.0424 7.81944 17.5501L12.4156 22.1463C12.6943 22.425 13.068 22.5507 13.4323 22.5234C13.7319 22.5011 14.0252 22.3754 14.2543 22.1463L23.4467 12.9539C23.9543 12.4462 23.9543 11.6231 23.4467 11.1154Z" />
  </svg>
)

export const TickIcon = (props: IconProps) => (
  <Icon {...props}>
    <TickIconSVG />
  </Icon>
)
