import styled, { css } from 'styled-components'
import { getCssVar } from 'theme'
import { rem } from 'helpers/functions'
import { InputFieldProps } from './types'

export const StyledInputField = styled.input<InputFieldProps>`
  background: ${getCssVar('input.bgColor')};
  color: ${getCssVar('input.textColor')};
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  font-weight: ${getCssVar('font.weight.regular')};
  line-height: 1;
  outline: none;
  padding: ${rem(10)};
  width: 100%;
  &::placeholder {
    color: ${getCssVar('input.placeholderColor')};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${getCssVar('input.disabled.bgColor')};
      border-color: ${getCssVar('input.disabled.borderColor')};
      color: ${getCssVar('input.disabled.textColor')};
      cursor: default;
      pointer-events: none;
    `}

  ${(props) =>
    props.error &&
    css`
      color: ${getCssVar('input.error.textColor')};
      background-color: ${getCssVar('input.error.bgColor')};
      border-color: ${getCssVar('input.error.borderColor')};
      &:focus-within {
        border-color: ${getCssVar('input.error.borderColor')};
      }
    `}
`

export const StyledInputWrapper = styled.div<Partial<InputFieldProps>>`
  display: flex;
  align-items: stretch;
  align-self: stretch;
  background-color: ${getCssVar('input.bgColor')};
  border: ${getCssVar('border.common')};
  border-radius: ${getCssVar('border.borderRadius')};
  cursor: auto;
  font-family: ${getCssVar('font.defaultFont')};
  font-size: ${(props) => getCssVar(`font.size.${props.sizeType || 'sm'}`)};
  font-weight: ${getCssVar('font.weight.regular')};
  color: ${getCssVar('input.textColor')};
  overflow: hidden;
  position: relative;
  transition: ${getCssVar('transitions.smooth')};
  width: 100%;

  ${(props) =>
    props.isFullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.isFullWidth &&
    css`
      flex-grow: 1;
    `}

  &:focus-within {
    border-color: ${getCssVar('input.focus.outline')};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${getCssVar('input.disabled.bgColor')};
      border-color: ${getCssVar('input.disabled.borderColor')};
      color: ${getCssVar('input.disabled.textColor')};
      cursor: default;
      pointer-events: none;
    `}

  ${(props) =>
    props.error &&
    css`
      color: ${getCssVar('input.error.textColor')};
      background-color: ${getCssVar('input.error.bgColor')};
      border-color: ${getCssVar('input.error.borderColor')};
      &:focus-within {
        border-color: ${getCssVar('input.error.borderColor')};
      }
    `}
`

export const StyledComponentWrapper = styled.div<InputFieldProps>`
  align-items: stretch;
  display: flex;
  ${(props) =>
    props.isFullWidth &&
    css`
      flex-grow: 1;
    `}
`

export const StyledErrorMessage = styled.div<Partial<InputFieldProps>>`
  ${(props) =>
    props.error &&
    css`
      color: ${getCssVar('input.error.textColor')};
      border-color: ${getCssVar('input.error.borderColor')};
      font-size: ${getCssVar('font.size.xs')};
      &:focus-within {
        border-color: ${getCssVar('input.error.borderColor')};
      }
      ${props.prefix} {
        background-color: transparent;
      }
    `}
`

export const StyledIcon = styled.div<Partial<InputFieldProps>>`
  height: ${rem(21)};
  width: ${rem(21)};
  position: absolute;
  top: ${rem(10)};
  right: ${rem(10)};
`