import { ToastProvider as RadixToastProvider } from '@radix-ui/react-toast'

import { CrossIcon } from 'components/icons/CrossIcon'

import { StyledClose, StyledContents, StyledExit, StyledToast, StyledViewport } from './styles'
import { ToastProps, ToastProviderProps } from './types'

export const ToastProvider = ({ children }: ToastProviderProps) => {
  return (
    <RadixToastProvider>
      {children}
      <StyledViewport />
    </RadixToastProvider>
  )
}

export const Toast = ({ children, ...props }: ToastProps) => {
  return (
    <StyledToast {...props}>
      <StyledContents>{children}</StyledContents>
      <StyledClose>
        <StyledExit>
          <CrossIcon />
        </StyledExit>
      </StyledClose>
    </StyledToast>
  )
}
