import { useEffect, useState, RefObject, useRef, MutableRefObject } from 'react'

export function useDistanceScrolled(distance: number) {
  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > distance
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled, distance])

  return scrolled
}

export function useElementScrolled(ref: RefObject<HTMLDivElement>, distance: number) {
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const targetRef = ref.current.getBoundingClientRect();
      setScrolled(targetRef.top <= distance);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref, distance]);

  return scrolled;
}
