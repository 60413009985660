import styled, { css } from 'styled-components'

import { TextAreaProps } from './types'
import { rem } from 'helpers/functions'
import { getCssVar } from 'theme'

export const StyledTextArea = styled.textarea<TextAreaProps>`
  width: ${(props) => props.customWidth || '100%'};
  height: ${(props) => props.customHeight || '100%'};
  resize: ${(props) => props.resize || 'both'};
  padding: ${rem(8)} ${rem(10)};
  background: ${getCssVar('input.bgColor')};
  color: ${getCssVar('input.textColor')};
  border: ${getCssVar('border.common')};
  border-radius: ${getCssVar('border.borderRadius')};
  font-weight: ${getCssVar('font.weight.regular')};
  font-size: ${getCssVar('font.size.sm')};

  &::placeholder {
    margin: ${rem(4)};
    color: ${getCssVar('input.placeholderColor')};
  }

  :focus-visible {
    outline: 2px solid ${getCssVar('input.focus.outline')};
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${getCssVar('input.disabled.bgColor')};
      border-color: ${getCssVar('input.disabled.borderColor')};
      color: ${getCssVar('input.disabled.textColor')};
      cursor: default;
      pointer-events: none;
    `}

  ${(props) =>
    props.error &&
    css`
      color: ${getCssVar('input.error.textColor')};
      background-color: ${getCssVar('input.error.bgColor')};
      border-color: ${getCssVar('input.error.borderColor')};
      &:focus-within {
        border-color: ${getCssVar('input.error.borderColor')};
      }
    `}
`
