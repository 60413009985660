import { Icon } from '.'
import { IconProps } from './types'

export const CrossIconSVG = ({ fill }: { fill?: string }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12L20 11L16 15L12 11L11 12L15 16L11 20L12 21L16 17L20 21L21 20L17 16L21 12Z"
      fill={fill || undefined}
    />
  </svg>
)

export const CrossIcon = (props: IconProps & { fill?: string }) => {
  const { fill } = props
  return (
    <Icon {...props}>
      <CrossIconSVG fill={fill} />
    </Icon>
  )
}