import styled, { css } from 'styled-components'
import { rem } from 'helpers/functions'
import { getCssVar } from 'theme'
import { Spacing } from 'theme/spacing'
import { SpaceProps } from './types'

function getSpacing(value: Spacing | number): string {
  if (typeof value === 'number') {
    return rem(value)
  }
  return getCssVar(`spacing.${value}`)
}

export const StyledSpace = styled.div<Partial<SpaceProps>>`
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};

  ${(props) =>
    props.align &&
    css`
      ${props.align === 'h' &&
      css`
        height: ${getSpacing(props.size || 'md')};
        width: 100%;
      `}
      ${props.align === 'v' &&
      css`
        height: 100%;
        width: ${getSpacing(props.size || 'md')};
      `}
    `}
`
