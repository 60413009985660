import React from 'react'
import { HeadingLevel } from 'types/styles'
import { StyledH1 } from './styles'

export type H1Props = {
  as?: HeadingLevel
} & React.HTMLAttributes<HTMLHeadingElement>

export const H1 = (props: H1Props) => {
  const { children, ...rest } = props
  return <StyledH1 {...rest}>{children}</StyledH1>
}
