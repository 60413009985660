import { hexToRGBa } from 'helpers/functions'
import { colors } from '../colors'

export const popover = {
  bgColor: colors.white,
  textColor: colors.darkGrey,
  textColorAlt: colors.blue,
  textColorDark: colors.slate,
  textColorActive: colors.navy,
  borderColor: colors.midGrey,
  borderRadius: '4px',
  shadowColor: hexToRGBa(colors.navy, 0.1),
}
