import { colors } from '../colors'

export const datePicker = {
  inputBgColor: colors.white,
  outerBorder: `1px solid ${colors.border}`,
  disabledInputBgColor: colors.lightGrey,
  pickerBorder: 'none',
  arrowColor: colors.white,
  navigatorButtonBgColor: colors.white,
  navigatorButtonHoverColor: undefined,
  pickerBorderColor: undefined,
  navigatorIconColor: undefined,
  calendarDayBgColor: colors.white,
  calendarDaySelectedBgColor: colors.teal,
  calendarDayHoverBgColor: undefined,
  calendarDayBorder: undefined,
}
