import { ChangeEvent, useState, useEffect } from 'react'

import { StyledTextArea } from './styles'
import { TextAreaProps } from './types'

export const TextArea = (props: TextAreaProps) => {
  const { value, onChange, placeholder = 'Please enter...', resize, disabled, error, customWidth, customHeight, fullWidth } = props

  const [displayedValue, setDisplayedValue] = useState(value)

  useEffect(() => {
    setDisplayedValue(value)
    return () => setDisplayedValue('')
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e)
    }
    setDisplayedValue(e.target.value)
  }

  return (
    <StyledTextArea
      value={displayedValue}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
      resize={resize}
      customWidth={customWidth}
      customHeight={customHeight}
      error={error}
      fullWidth={fullWidth}
    />
  )
}
