import { BreakpointDirectionProps, BreakpointProps } from 'types/layout'

export function camelCaseToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
}

export function rem(value: number): string {
  return `${value / 16}rem`
}

export function lightenDarkenColor(col: string, amt: number): string {
  let usePound = false
  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }
  const num = parseInt(col, 16)
  let b = ((num >> 8) & 0x00ff) + amt
  let r = (num >> 16) + amt
  let g = (num & 0x0000ff) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  if (b > 255) b = 255
  else if (b < 0) b = 0

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export function hexToRGBa(h: string, a: number): string {
  let r = '0'
  let g = '0'
  let b = '0'

  if (h.length === 4) {
    r = '0x' + h[1] + h[1]
    g = '0x' + h[2] + h[2]
    b = '0x' + h[3] + h[3]
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2]
    g = '0x' + h[3] + h[4]
    b = '0x' + h[5] + h[6]
  }

  return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')'
}


export function breakpoint(direction: BreakpointDirectionProps, breakpoint: BreakpointProps | number): string {
  const directions = {
    down: 'max-width:',
    up: 'min-width:',
  }

  const breakpointDirection = directions[direction]
  const breakpointWidth = mapBreakpointValues(breakpoint).toString() + 'px'

  return `@media only screen and (${breakpointDirection} ${breakpointWidth})`
}


export function mapBreakpointValues(breakpoint: BreakpointProps | number) {
  const breakpoints = {
    xs: 500,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  }

  return typeof breakpoint === 'string' ? breakpoints[breakpoint] : breakpoint
}
