import styled from 'styled-components'

export const StyledPageContent = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  z-index: 9;
`
